import React from 'react';
import Electro1 from '../../static/images/Landing Page Assets/electro1-eva.png';
import Electro2 from '../../static/images/Landing Page Assets/electro2-eva.png';
import Electro3 from '../../static/images/Landing Page Assets/electro3-eva.png';
import PCBBackground from '../../static/images/Landing Page Assets/background-4.png';
const PCB = () => {
    const PCBInfoData=[
        {
            image:Electro1
        },
        {
            image:Electro2
        },
        {
            image:Electro3
        }
    ]
  return (
    <div className='eva-work-section-wrapper'>
        <div className='PCB-bannerImage-container'>
            <img src={PCBBackground} alt="" />
        </div>
        <div className='eva-work-section-top'>
            <h1 className='eva-primary-heading-gradient'>PCB Close-ups</h1>
        </div>
        <div className='eva-work-section-bottom'>
            {
                PCBInfoData.map((data) => (
                    <div className='eva-work-section-info'>
                        <div className='eva-info-boxes-img-container'>
                            <img src={data.image} alt="" />
                        </div>
                    </div>
                )
                )
            }
        </div>

    </div>
  )
}

export default PCB;