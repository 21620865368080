import React, { useState, useEffect } from "react";
import Navbar from "../Components/Nav";
import Footer from "../Components/Footer";
import CatalinChelmus from "../static/images/Poze editate/Chelmus Catalin.jpg";
import "../static/css/team.css";
import waveSeparator from "../static/images/separators/wave.png";
import bgStructure from "../static/images/components/rocket_background_bg.png";
import bgElectronics from "../static/images/components/rocket_electronics_bg.png";
import bgLogistics from "../static/images/components/rocket_logistics_bg.png";
import { RiTeamLine } from "react-icons/ri";
import TeamMember from "../Components/Images"

import MemberCard from "../Components/MemberCard";
import { Footer2024 } from "../Components/Footer_2024";
const TeamPage = (props) => {
  return (
    <div className="App scroll-snap" >
      <Navbar />
      <header className="App-header Team-main-bg align-items-center" id="section-scroll">
        <div className="row w-75">
          <div className="col-4">
            <MemberCard image={CatalinChelmus} name="Catalin Chelmus" role="Team Leader" col="9"/>
          </div>

          <div className="col-8 d-flex justify-content-center align-items-center" id="team-about-us">
            <div className="row d-flex justify-content-center align-items-center ">
              <h1 className="big-title">About us</h1>
              <p>
                Students 2Space is an NGO, established in 2021, dedicated to
                promoting the space industry among students, through a hands-on
                approach.{" "}
              </p>
            </div>
          </div>
        </div>
      </header>


      <section className="departments m-0 p-4 row bg-black" id="section-scroll">
        <div className="deparment  col-7 d-flex align-items-center" >
          <div className="row d-flex justify-content-center">
            <h1 className="big-title mb-4">STRUCTURE DEPARMENT</h1>

            <div className="d-flex justify-content-center mt-4">
              <div className="dp-leader mt-4">
                <div class="col-12">
                  <MemberCard image={TeamMember.StefanUrsache} name="Stefan Ursache" role="Coordinator" col="12"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5 mt-0 p-0">
          <img
            src={bgStructure}
            alt="Structure BG"
            className="mr-0 mt-0 shaped-bg"
          />
        </div>
      </section>
      <section className="departments m-0  col-12 p-4 d-flex justify-content-center" id="bg-reverse">
        <div className="row d-flex justify-content-center mt-4 w-75">
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.AgriciMihai} role={"3D Design"} name={"Agrici Mihai"}/>
                <MemberCard image={TeamMember.GheormaTudor} role={"Mechatronics"} name={"Gheorma Tudor"}/>

            </div>
          </div>
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.NitoiuVlad} role={"Propulsion"} name={"Nitoiu Vlad"}/>
                <MemberCard image={TeamMember.TomaValentin} role={"Materials"} name={"Toma Valentin"}/>

            </div>
          </div>
        </div>
      </section>
      <section className="departments m-0  col-12 p-4 d-flex justify-content-center" >
        <div className="row d-flex justify-content-center mt-4 w-75">
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.DoanaSilviu} role={"3D Design"} name={"Doana Silviu"}/>
                <MemberCard image={TeamMember.PaslaruGeorge} role={"Payload"} name={"Paslaru George"}/>

            </div>
          </div>
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.RogozDiana} role={"Innovation"} name={"Rogoz Diana"}/>
                <MemberCard image={TeamMember.SincanAlexandru} role={"Recovery"} name={"Sincan Alexandru"}/>

            </div>
          </div>
        </div>
      </section>
      


      {/* ======== ELECTRONICS ======== */}

      <section className=" m-0 p-4 row " id="section-scroll" >
        <div className="col-5 mt-0 p-0">
          <img
            src={bgElectronics}
            alt="Structure BG"
            className="mr-0 mt-0 shaped-bg asp_rat_3_2"
          />
        </div>
        <div className="deparment  col-7 d-flex align-items-center" >
          <div className="row d-flex justify-content-center">
            <h1 className="big-title mb-4">ELECTRONICS DEPARMENT</h1>

            <div className="d-flex justify-content-center mt-4">
              <div className="dp-leader mt-4">
                <div class="col-12">
                  <MemberCard image={TeamMember.RadoviciMihai} name="Radovici Mihai" role="Coordinator" col="12"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
      <section className="m-0  col-12 p-4 d-flex justify-content-center" id="bg-reverse">
        <div className="row d-flex justify-content-center mt-4 w-75">
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.ConstantinescuAlex} role={"Hardware"} name={"Constantinescu Alex"}/>
                <MemberCard image={TeamMember.MogdaIonut} role={"Member"} name={"Mogda Ionut"}/>

            </div>
          </div>
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.NicolaAndrei} role={"Member"} name={"Nicola Andrei"}/>
                <MemberCard image={TeamMember.PislaruVlad} role={"Embedded"} name={"Pislaru Vlad"}/>

            </div>
          </div>
        </div>
      </section>
      

      {/* ======= LOGISTICS ======== */}
      <section className="departments m-0 p-4 row " id="section-scroll">
        <div className="deparment  col-7 d-flex align-items-center" >
          <div className="row d-flex justify-content-center">
            <h1 className="big-title mb-4">LOGISTICS DEPARMENT</h1>

            <div className="d-flex justify-content-center mt-4">
              <div className="dp-leader mt-4">
                <div class="col-12">
                  <MemberCard image={TeamMember.GogiuMara} name="Gogiu Mara" role="Coordinator" col="12"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5 mt-0 p-0">
          <img
            style={{width:"60%"}}
            src={bgLogistics}
            alt="Structure BG"
            className="mr-0 mt-0 shaped-bg"
          />
        </div>
      </section>
      <section className="departments m-0  col-12 p-4 d-flex justify-content-center" id="bg-reverse">
        <div className="row d-flex justify-content-center mt-4 w-75">
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.MarinAndreea} role={"Web"} name={"Marin Andreea"}/>
                <MemberCard image={TeamMember.PopescuAndreea} role={"Design"} name={"Popescu Andreea"}/>

            </div>
          </div>
          <div className="col-6">
            <div className="row">
                
                <MemberCard image={TeamMember.PaltinusAlexandra} role={"Communication"} name={"Paltinus Alexandra"}/>
                <MemberCard image={TeamMember.DraghiciMadalina} role={"Design"} name={"Draghici Madalina"}/>

            </div>
          </div>
        </div>
      </section>
      <section className="departments m-0  col-12 p-4 d-flex justify-content-center" id="bg-reverse">
        <div className="row d-flex justify-content-center mt-4 w-75">
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.ManoiuMiruna} role={"Design"} name={"Manoiu Miruna"}/>
                <MemberCard image={TeamMember.NicaDaniel} role={"Media"} name={"Nica Daniel"}/>

            </div>
          </div>
          <div className="col-6">
            <div className="row">

                <MemberCard image={TeamMember.IvanVictor} role={"Media"} name={"Ivan Victor"}/>
                <MemberCard image={TeamMember.BalbarauMaria} role={"Design"} name={"Balbarau Maria"}/>

            </div>
          </div>
        </div>
      </section>
      <section className=" m-0  col-12 p-4 d-flex justify-content-center" >
        <div className="row d-flex justify-content-center align-items-center mt-4 w-75">

          <div className="col-6">
            <div className="row d-flex justify-content-center align-items-center">
                <MemberCard image={TeamMember.TintarBianca} role={"Member"} name={"Tintar Bianca"} />
            </div>
          </div>
          
        </div>
      </section>
      <Footer2024 />
    </div>
  );
};


export default TeamPage;
