import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';


 const  MultiActionAreaCard = ({img, name, description1, description2}) =>{
  return (

    <div style={{ display: 'flex', justifyContent: 'center' }}>

    <Card sx={{ maxWidth: 345 , marginBottom: 10}}>
      <CardActionArea>
        <CardMedia
          component="img"
          className='base-image'
          image={img}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className='fs-5'>
            {name}
          </Typography>
          
          <Typography variant="body2" color="text.secondary" className='fs-6 fw-bold'>
            {description1}
            
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description2}
            
          </Typography>
        </CardContent>
      </CardActionArea>
      
    </Card>

    </div>

  );
}

export default  MultiActionAreaCard;
