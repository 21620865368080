import React from "react";
import Form230Page from "./Form230Page";
import logoImg from "../static/images/logos/logo.png"
import Navbar from '../Components/Nav';
import { Footer2024 } from "../Components/Footer_2024";
const Form23Page = () => {
    return (
        <div className="App" id="form230">
            <Navbar/>
            <header className="App-header">
                <img className="title-logo" src={logoImg}  alt=""/>
                <Form230Page/>
            </header>
            <Footer2024 />

        </div>
    )
}

export default Form23Page;