import StefanUrsache from "../static/images/Poze editate/Ursache Stefan.jpg";
import AgriciMihai from "../static/images/Poze editate/Agrici Mihai.PNG";
import PislaruVlad from "../static/images/Poze editate/Paslaru Vlad.jpg";
import RadoviciMihai from "../static/images/Poze editate/Radovici Mihai.jpg";
import GheormaTudor from "../static/images/Poze editate/Gheroma tudor.png";
import ConstantinescuAlex from "../static/images/Poze editate/constantinescu alex.PNG";
import MarinAndreea from "../static/images/Poze editate/andreea marin.PNG";
import PopescuAndreea from "../static/images/Poze editate/andreea popescu v2.png";
import DoanaSilviu from "../static/images/Poze editate/doana silviuv.png";
import DraghiciMadalina from "../static/images/Poze editate/draghici madalina.png";
import GogiuMara from "../static/images/Poze editate/gogiu mara.png";
import IvanVictor from "../static/images/Poze editate/ivan victor.png";
import MalinasAndrei from "../static/images/Poze editate/malinas andrei.png";
import ManoiuMiruna from "../static/images/Poze editate/manoiu miruna.png";
import MogdaIonut from "../static/images/Poze editate/mogda ionut.PNG";
import NicaDaniel from "../static/images/Poze editate/nica dani.PNG";
import NicolaAndrei from "../static/images/Poze editate/nicola andrei.PNG";
import NitoiuVlad from "../static/images/Poze editate/nitoiu vlad.PNG";
import PaltinusAlexandra from "../static/images/Poze editate/paltinus alexandra.png";
import PaslaruGeorge from "../static/images/Poze editate/paslaru george.png";
import RogozDiana from "../static/images/Poze editate/rogoz diana.PNG";
import SincanAlexandru from "../static/images/Poze editate/sincan alexandru.png";
import TomaValentin from "../static/images/Poze editate/toma valentin.PNG";
import TintarBianca from "../static/images/Poze editate/Bianca tintar.PNG";
import BalbarauMaria from "../static/images/Poze editate/maria balbarau.png";

export default 
{   
    StefanUrsache, 
    AgriciMihai, 
    PislaruVlad,
    RadoviciMihai,
    GheormaTudor,
    ConstantinescuAlex,
    MarinAndreea,
    PopescuAndreea,
    DoanaSilviu,
    DraghiciMadalina,
    GogiuMara,
    IvanVictor,
    MalinasAndrei,
    ManoiuMiruna,
    MogdaIonut,
    NicaDaniel,
    NicolaAndrei,
    NitoiuVlad,
    PaltinusAlexandra,
    PaslaruGeorge,
    RogozDiana,
    SincanAlexandru,
    TomaValentin,
    TintarBianca,
    BalbarauMaria
}