import React from "react";

import { useState, useEffect, useContext } from "react";
import { Footer2024 } from "../Components/Footer_2024";
import { Link } from "react-router-dom";

import "../static/css/HomePage/Header.css";
import Navbar2sp from "../Components/Nav";

import logoImg from "../static/images/logos/logo_cut.png";
import {FaFacebook, FaEnvelope, FaInstagram, FaLinkedin, FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight, FaRegCopyright} from "react-icons/fa"
import { FiYoutube } from 'react-icons/fi';
//Alte poze
import AboutUsPicture from "../static/images/form23/img2_copy.jpeg";
import Carousel from 'react-bootstrap/Carousel';
import EvaProject from "../static/images/HomePage/Eva_project_home.jpeg";
import LunaProject from '../static/images/HomePage/LUNA_project_home.png';
import Event1 from '../static/images/HomePage/Event1.jpg';
import Event2 from '../static/images/HomePage/Event2.jpg';
import Event3 from '../static/images/HomePage/Event3.png';
import { IoIosArrowForward } from "react-icons/io";

// Membrii
import CatalinCHelmus from "../static/images/Poze editate/Chelmus Catalin_copy.jpg";
import StefanUrsache from "../static/images/Poze editate/Ursache Stefan_copy.jpg";
import RadoviciMihai from "../static/images/Poze editate/Radovici Mihai_copy.jpg";
import PislaruVlad from "../static/images/Poze editate/Paslaru Vlad_copy.jpg";
import MaraGoghiu from "../static/images/Poze editate/Mara Goghiu_copy.jpg";
import Misu from "../static/images/Poze editate/Agrici Mihai_copy.png";
import Tudor from "../static/images/Poze editate/Gheroma tudor_copy.png";
//Sponsori

import RedBull from '../static/images/HomePage/RedBull.png';
import Eelisa from '../static/images/HomePage/EELISA.png';
import EUROC from '../static/images/HomePage/EUROC.png';
import SpacePort from '../static/images/HomePage/SpacePort.png';
import Sponsor from "../Components/Sponsor";
import UPB_logo from '../static/images/Sponsors/LOGO_UPB_205_3.3_ro.png'
import LogoETTI from '../static/images/Sponsors/LogoETTI.png'
import CAMPUS_logo from '../static/images/Sponsors/campus_logo_hires.png'
import FIA_logo from '../static/images/Sponsors/FIA_logo_hires.png'
import ACS_logo from '../static/images/Sponsors/acs_logo.png'
import PRAGMATIC_logo from '../static/images/Sponsors/logo-pragmatic-play.png'
import VODAFONE_logo from '../static/images/Sponsors/vodafone_logo_hires.png'
import Ansys_logo from '../static/images/Sponsors/logoAnsys.png'
import INAS_logo from '../static/images/Sponsors/logoINAS.png'
import LogoINCAS from '../static/images/Sponsors/LogoINCAS.png'
import AeroSpaceJobs_logo from '../static/images/Sponsors/AerospaceJobs.png'
import ASFIA_logo from '../static/images/Sponsors/ASFIA.png'
import ROSPIN_logo from '../static/images/Sponsors/ROSPIN.png'

const HomePage = function(){

    const [teamSlide, setTeamSlide] = useState(true);

    return <>
        <div className="space-body">

            <Navbar2sp/>
            <div className="sp-header" id="home-header">
                <div className="home-content">
                    <img className="title-logo" src={logoImg}  alt=""/>
                    <div className="d-flex justify-content-around" >
                        <button className="header-button" id="glowing-button-margin">
                            <Link to="/AboutUs"  className="text-white" >
                                About Us
                            </Link>
                        </button>
                        <button className="header-button">
                            <a href="#projects_presentation">
                                    Projects
                            </a>
                        </button>
                    </div>
                </div>
                
            </div>

            {/* HERO SECTION */}
            <section id="hero-home-section">
                <div id="hero1">
                    <h1 id="heading-glowing">Expanding possibilities</h1>
                    {/* <h1 id="heading-glowing">One Rocket at a Time</h1> */}
                </div>
                <div id="hero2">
                    <p id="p-hero2">Join our journey to the outer limits of aerospace engineering</p>
                    <div id="hero-buttons-container">
                        <button className="hero-button-purple">
                            <Link to="/form230"  className="text-white" >
                                Support Us
                            </Link>
                        </button>
                        <button className="hero-button-transparent">
                            <Link to="/AboutUs" className="text-white" >
                                Follow 
                            </Link>
                        </button>
                    </div>
                </div>
            </section>

            {/* About Us */}
            <section className=" d-flex flex-row mh-100 justify-content-around align-items-center" id="about-us-section" >
                <div className="d-flex flex-column pl-0 w-50 " id="about-us-content">
                    <h2 id="" className="hover-glowing">Education is the key</h2>
                    <h2 id="heading" className="text-white text-bold mt-4">Supporting Student-led Space Initiatives: Fueling Innovation, Enriching Education, and Impacting Communities</h2>
                    <p id="p-hero2">By supporting 2SPACE, you contribute to technological advancements, provide educational opportunities, and make a positive impact on communities.</p>
                </div>
                <div className="d-flex flex-column w-50 p-5" id="about-us-image-div">
                    <img src={AboutUsPicture} className="w-75 align-self-center" id="about-us-picture" />
                </div>
            </section>

            {/* TEAM SECTION */}
            <section id="team-home-section">
                <h6 className="h6-home">Coordinators</h6>
                <h1  className="h1-light">Meet Our Team</h1>
                <h5 className="home">Dedicated students building rockets with passion</h5>
                <svg width="0" height="0">
                    <defs>
                        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="100%" y2="0%">
                            <stop stopColor="#FF4A40" offset="0%" />
                            <stop stopColor="#6B0FF6" offset="80%" />
                        </linearGradient>
                    </defs>
                </svg>
                <Carousel data-bs-theme="dark" slide={true} fade={true}  controls={false} indicators={false} activeIndex={!teamSlide ? 1 : 0}>
                    <Carousel.Item>
                        <div id="home-team-container" className={"w-100 d-flex flex-row justify-content-between  "}>
                            <div className="w-100 d-flex flex-row justify-content-around">

                                <div className="home-member-card">
                                    <img className="home-member-image " src={CatalinCHelmus}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" className="text-white">Catalin Chelmus</h6>
                                        <h6 id="h6-member-title" className="text-white">Team Leader</h6>
                                        <p id="p-member-description" className="text-white">Catalin is an experienced aerospace student guiding the team and setting team's projects.</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white"  />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white"  />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white"  />
                                        </div>
                                    </div>
                                </div>

                                <div className="home-member-card">
                                    <img className="home-member-image" src={StefanUrsache}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" >Ursache Stefan</h6>
                                        <h6 id="h6-member-title" className="text-white">Structure coordinator</h6>
                                        <p id="p-member-description" className="text-white">Stefan is an Industrial engineer that is responsible for the design and integrity of the structure.</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white" />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white" />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="w-100 d-flex flex-row justify-content-around">
                                <div className="home-member-card">
                                    <img className="home-member-image" src={RadoviciMihai}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" className="text-white">Radovici Mihai</h6>
                                        <h6 id="h6-member-title" className="text-white">Electronics coordinator</h6>
                                        <p id="p-member-description" className="text-white">Mihai is an Computer Science student that manages the implementation of all electronic systems.</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white"  />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white"  />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white"  />
                                        </div>
                                    </div>
                                </div>

                                <div className="home-member-card">
                                    <img className="home-member-image" src={MaraGoghiu}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" className="text-white">Gogiu Mara</h6>
                                        <h6 id="h6-member-title" className="text-white">Logistics coordinator</h6>
                                        <p id="p-member-description" className="text-white">Mara is an aerospace student that handles the planning and execution of all logistical aspects.</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white"  />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white"  />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white"  />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div id="home-team-container" className={"w-100 d-flex flex-row justify-content-between  "}>
                            <div className="w-100 d-flex flex-row justify-content-around">

                                <div className="home-member-card">
                                    <img className="home-member-image " src={Misu}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" className="text-white">Agrici Mihai</h6>
                                        <h6 id="h6-member-title" className="text-white">3D Design</h6>
                                        <p id="p-member-description" className="text-white">Mihai is a skilled aerospace engineer who loves pushing the boundaries or rocket science</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white"  />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white"  />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white"  />
                                        </div>
                                    </div>
                                </div>

                                <div className="home-member-card">
                                    <img className="home-member-image" src={PislaruVlad}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" >Pislaru Vlad</h6>
                                        <h6 id="h6-member-title" className="text-white">Web coordinator</h6>
                                        <p id="p-member-description" className="text-white">Vlad is a talented Computer Science engineer who loves pushing his boundaries regardless the field</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white" />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white" />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className="w-100 d-flex flex-row justify-content-around">

                                <div className="home-member-card">
                                    <img className="home-member-image" src={Tudor}/>
                                    <div className="home-member-content">
                                        <h6 id="h6-member-name" className="text-white">Gheorma Tudor</h6>
                                        <h6 id="h6-member-title" className="text-white">Safety range</h6>
                                        <p id="p-member-description" className="text-white">Tudor is a good Mechatronics engineer who design and simulates every moving mechanism.</p>
                                        <div className="d-flex flex-row">
                                            <FaFacebook   className="fa-gradient-pink-purple" color="white"  />
                                            <FaInstagram className="fa-gradient-pink-purple" color="white"  />
                                            <FaLinkedin className="fa-gradient-pink-purple" color="white"  />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="home-member-card" id="view-more">
                                    <Link to="/team"  className="text-white" >
                                        <h4>View All</h4>
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </Carousel.Item>
                   
                </Carousel>

                <div className="d-flex w-100 justify-content-between mt-5 " id="keep-controls-margin">
                    <div className="d-flex flex-row">
                        <div className="dot-home-team" id={!teamSlide ? "active": "not-active"}>

                        </div>
                        <div className="dot-home-team" id={teamSlide ? "active": "not-active"}>
                            
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <FaRegArrowAltCircleLeft color="white" id="arrows" onClick={() => {setTeamSlide(!teamSlide)}}/>
                        <FaRegArrowAltCircleRight color="white" id="arrows"  onClick={() => {setTeamSlide(!teamSlide)}}/>
                    </div>
                </div>
                <div id="hero2" className="mt-5">
                    <h2 id="heading" className="text-white text-bold">Expanding possibilities</h2>
                    <p id="p-hero2">Join our journey to the outer limits of aerospace engineering</p>
                    <div id="hero-buttons-container">
                        <button className="hero-button-transparent " disabled id="glowing-button-margin" title="Soon !">
                            <Link to="/#"  className="text-white" >
                                Join us
                            </Link>
                        </button>
                        <button className="hero-button-transparent ml-3">
                            <Link to="/team"  className="text-white" >
                                Team
                            </Link>
                        </button>
                    </div>
                </div>

            </section>


            {/* Projects */}
            <section className="d-flex flex-column justify-content-center align-items-center" id="projects_presentation">
                <div className="w-50 d-flex flex-column justify-content-center align-items-center mb-5">
                    <h6 className="h6-home">Projects</h6>
                    <h2 id="heading" className="text-white text-bold">Rocket Projects Showcase</h2>
                    <h6 className="h6-home">Explore our past and current rocket projects</h6>
                </div>
                <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-5 mb-5 home-project-container" >
                    <div className="w-100" id="img-project-div">
                        <img className="w-100 img-project" src={EvaProject}/>
                    </div>
                    <div className="w-100 d-flex flex-row mt-4">
                        <div className="w-50 d-flex flex-column" id="projects_tags">
                            <div className="w-50 d-flex flex-row justify-content-between">
                                <h4 id="heading" className=" text-white text-bold">Eva Project</h4>
                                <h6 id="heading" className="text-white ">
                                    <u>
                                        <Link to="/project-eva"  className="text-white" >
                                            See more
                                        </Link>
                                    </u>
                                </h6>
                            </div>
                            <div className="w-50 d-flex flex-row mt-1 justify-content-between">
                                <button className="hero-tag-purple">Competition</button>
                                <button className="hero-tag-purple">Innovation</button>
                                <button className="hero-tag-purple">Achievements</button>
                            </div>
                        </div>
                        <div className="w-50 d-flex flex-row justify-content-center align-items-center">
                            <p id="p-hero3">Project EVA was a groundbreaking innovation, at its time it was the most powerful rocket in the South-East part of Europe, build by students.</p>
                        </div>
                    </div>
                </div>
                <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-5 home-project-container" >
                    <div className="w-100" id="img-project-div">
                        <img className="w-100 img-project" src={LunaProject}/>
                    </div>
                    <div className="w-100 d-flex flex-row mt-4">
                        <div className="w-50 d-flex flex-column" id="projects_tags">
                            <div className="w-50 d-flex flex-row justify-content-between">
                                <h4 id="heading" className=" text-white text-bold">Luna Project</h4>
                                <h6 id="heading" className="text-white ">
                                    <u>
                                        <Link to="/project-eva"  className="text-white" >
                                            See more
                                        </Link>
                                    </u>
                                </h6>
                            </div>
                            <div className="w-50 d-flex flex-row mt-1 justify-content-between">
                                <button className="hero-tag-purple">Space Port</button>
                                <button className="hero-tag-purple">Engineering</button>
                                <button className="hero-tag-purple">Achievements</button>
                            </div>
                        </div>
                        <div className="w-50 d-flex flex-row justify-content-center align-items-center">
                            <p id="p-hero3">The LUNA Rocket project was a our first rocket that we participated at Spaceport America Cup.</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center mt-5 mr-0">
                    <button className="hero-button-transparent" title="More soon!">View All</button>
                </div>  
            </section>

            {/* Events */}
            <section  className="d-flex flex-column justify-content-center align-items-center" id="event_section">
                <div className="w-50 d-flex flex-column justify-content-center align-items-center mb-5">
                    <h6 className="h6-home ">Rocketeers</h6>
                    <h2 id="" className="hover-glowing">Events</h2>
                    <h6 className="h6-home">Stay updated on our upcoming and past events</h6>
                </div>
                <div className="events_row">
                    <EventCardElement 
                        dayOfWeek="Thu"
                        dayNumber="05"
                        date="Jul 2022"
                        tag="Test"
                        title="Reaching for the Stars"
                        location="Test Launch, Smardan, Galati"
                        location_link="https://maps.app.goo.gl/JHcXDfZRxtYAkms37"
                        event_img={Event1}
                    />
                    <EventCardElement 
                        dayOfWeek="Wes"
                        dayNumber="27"
                        date="Oct 2022"
                        tag="Exposure"
                        title="Exploring New Horizons"
                        location="BIAS, Bucharest"
                        location_link="https://maps.app.goo.gl/pFws5u7GhPnzsxDA6"
                        event_img={Event2}
                    />
                    <EventCardElement 
                        dayOfWeek="Sut"
                        dayNumber="09"
                        date="Nov 2023"
                        tag="Learning"
                        title="Igniting Dreams Together"
                        location="Qube2Space, Bucharest, UPB"
                        location_link="https://maps.app.goo.gl/BfMJpBSHYYs8v8yE8"
                        event_img={Event3}
                    />
                    
                </div>
                <div className="d-flex flex-row justify-content-center mt-5 mr-0">
                    <button className="hero-button-transparent">
                        <Link to="/AboutUs#work_enviroment"  className="text-white" >
                            View All
                        </Link>
                    </button>
                </div> 
            </section>

            {/* Awards */}
            <section className="d-flex flex-row " id="awards_section">
                <div className=" w-50 d-flex  flex-column justify-content-start align-item-center" >
                    <h2 id="" className="hover-glowing">Awards and Recognitions</h2>
                    <p id="p-hero2">Discover the achievements and accolades received by 2SPACE.</p>
                    <div className="d-flex flex-row justify-content-start align-items-center">
                        <button className="hero-button-transparent">
                            <Link to="/AboutUs" className="text-white">
                                Learn more
                            </Link>
                        </button>
                        <button className="hero-button-transparent border-0" title="Soon !" >
                            <Link to="/#" className="text-white">
                                Join us
                                <IoIosArrowForward className="m-1" />
                            </Link>
                            
                        </button>

                    </div>
                </div>
                <div className="w-50 d-flex  events_row mt-0 justify-content-center p-3">
                    <div className=" glowing-frame  " id="glow-frame-0">
                        <img src={EUROC} />
                    </div>
                    <div className=" glowing-frame  " id="glow-frame-180">
                        <img src={SpacePort} />
                    </div>
                    <div className="glowing-frame  " id="glow-frame-0">
                        <img src={Eelisa} />
                    </div>
                    <div className=" glowing-frame  " id="glow-frame-180">
                        <img src={RedBull} />
                    </div>
            </div>
            </section>

            {/* Partners */}
            <section className="d-flex flex-column nustify-content-center align-items-center "  > 
                <div className="w-50 d-flex flex-column justify-content-center align-items-center mb-5">
                    <h6 className="h6-home ">Support</h6>
                    <h2 id="" className="hover-glowing">Partners</h2>
                    <h6 className="h6-home">Stay updated on our upcoming and past events</h6>
                </div>
                <div className="d-flex flex-row" id="sponsors_section">
                    <Sponsor name="FIA" image={FIA_logo} description="The Faculty of Aerospace Engineering (FAE) Mission is defined in the UPB Charter and is that of advanced research and education."></Sponsor>
                    <Sponsor name="ACS" image={ACS_logo} description="The Faculty of Automatic Control and Computers prepares engineers specialized in the fields of Systems Engineering and Computers and Information Technology."></Sponsor>
                    <Sponsor name="CAMPUS" image={CAMPUS_logo} description="Research Institute „CAMPUS” is UPB's advanced research and development center for multi - and inter - disciplinary technologies."></Sponsor>
                    <Sponsor name="ETTI" image={LogoETTI} description="The Romanian school of Electronics and Telecommunications was set up in 1920 as part of the Polytechnic School of Bucharest."></Sponsor>
                    <Sponsor name="UNSTPB" image={UPB_logo} description="The University POLITEHNICA of Bucharest is the oldest and most prestigious engineering school in Romania."></Sponsor>
                </div>
                
            </section>

            {/* Sponsors */}
            <section className="d-flex flex-column nustify-content-center align-items-center "  > 
                <div className="w-50 d-flex flex-column justify-content-center align-items-center mb-5">
                    <h6 className="h6-home ">Support</h6>
                    <h2 id="" className="hover-glowing">Sponsors</h2>
                    <h6 className="h6-home">Stay updated on our upcoming and past events</h6>
                </div>
                <div className="d-flex flex-row" id="sponsors_section">
                    <Sponsor name="UNSTPB" image={UPB_logo} description="The University POLITEHNICA of Bucharest is the oldest and most prestigious engineering school in Romania."></Sponsor>
                    <Sponsor name="PragmaticPlay" image={PRAGMATIC_logo} description="Pragmatic Play is a leading game developer providing player-favourites to the most successful global brands in the iGaming industry."></Sponsor>
                    <Sponsor name="Ansys" image={Ansys_logo} description="For more than 50 years, Ansys software has enabled innovators across industries to push the boundaries of product design by using the predictive power of simulation."></Sponsor>
                    <Sponsor name="INAS" image={INAS_logo} description="Leading technical consulting centre providing prompt and quality engineering services for applications from automotive and heavy equipment to nuclear and defence"></Sponsor>
                    <Sponsor name="VODAFONE" image={VODAFONE_logo} description="Vodafone Romania started business in 1997 and continues to deliver the best communication services."></Sponsor>
                    
                </div>
                
            </section>

            {/* Contributors */}
            <section className="d-flex flex-column nustify-content-center align-items-center mt-5 mb-5"  > 

                <div className="w-50 d-flex flex-column justify-content-center align-items-center mb-5">
                    <h6 className="h6-home ">Support</h6>
                    <h2 id="" className="hover-glowing">Contributors</h2>
                    <h6 className="h6-home">Stay updated on our upcoming and past events</h6>
                </div>
                <div className="d-flex flex-row" id="sponsors_section">
                    <Sponsor name="INCAS" image={LogoINCAS} description="National Institute for Aerospace Research “Elie Carafoli” is the leading research establishment in aerospace sciences in Romania."></Sponsor>
                    <Sponsor name="AeroSpaceJobs" image={AeroSpaceJobs_logo} description=" AeroSpace Jobs is an organization that consists of a place dedicated to the aerospace field in Romania with the aim of creating jobs."></Sponsor>
                    <Sponsor name="ASFIA" image={ASFIA_logo} description="It is a student organization within the The Faculty of Aerospace Engineering."></Sponsor>
                    <Sponsor name="ROSPIN" image={ROSPIN_logo} description="The mission of ROSPIN is to develop the Romanian Space Ecosystem through various activities such as educational programmes."></Sponsor>
                </div>
                
            </section>

            <Footer2024/>
        </div>
    </>
}


const EventCardElement = (props) => {
    return(
        <div className="event_card">
            <div className="event_card_body">
                <div className="event_card_header">
                    <img className="event_card_img" src={props.event_img}></img>
                    <div className="event_card_img_overay"></div>
                    <div className="event_card_date">
                        <h6>
                            {props.dayOfWeek}
                        </h6>
                        <h3>
                            {props.dayNumber}
                        </h3>
                        <h6>
                            {props.date}
                        </h6>
                    </div>
                </div>
                <div className="w-50 d-flex flex-row mt-3 justify-content-between">
                    <button className="hero-tag-purple">{props.tag}</button>
                </div>
                <div className="event_card_description">
                    <h4 className="mt-4 event_card_title">{props.title}</h4>
                    <a className="event_card_location mt-1" href={props.location_link}>Maps Location</a>
                    <p className="event_card_location mt-3 mb-3 ">
                        {props.location}
                    </p>
                    <p className="event_card_location mt-3 mb-2">View event <IoIosArrowForward className="m-3" /></p>

                </div>
            </div>
        </div>
    );
    
}
export default HomePage;