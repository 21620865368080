const events = [
    {
        dateTitle: "10 June - 31 July 23:59",
        date:"31 July 2024 23:59",
        title: "Theoretical Design",
        description: "Team registration and document attachment"
    },
    {
        dateTitle: "1st August - 18:00",
        date:"1 August 2024 18:00",
        title: "Eligible teams",
        description: "Eligible teams for competition participation announcement"
    },
    {
        dateTitle: "4th August - 18:00",
        date:"4 August 2024 18:00",
        title: "Q&A meet - DISCORD",
        description: "Q&A meet - DISCORD where you can get feedback"
    },
    {
        dateTitle: "28th August - 23:59",
        date:"28 August 2024 23:59",
        title: "Technical Report",
        description: "Preliminary Technical Report Submission"
    },
    {
        dateTitle: "22th September - 23:59",
        date:"22 September 2024 23:59",
        title: "Final Technical Report Submission",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "23th September - 18:00",
        date:"23 September 2024 23:59",
        title: "Participation confirmation",
        description: "Announcement of the eligible teams for project funding"
    },
    {
        dateTitle: "1st - 3rd november",
        date:"2 November 2024 23:59",
        title: "Pocketqube Construction",
        description: "Satellite construction based on the Final Technical Report"
    },
    {
        dateTitle: "3rd november",
        date:"21 November 2024 23:59",
        title: "RoboChallange",
        description: "Satellite presentation within RoboChallange. Rocket integration and flight tests "
    },
    {
        dateTitle: "3rd november",
        date:"21 November 2024 23:59",
        title: "RoboChallange",
        description: "Nomination of the 3 winning teams"
    }

]

export default events;