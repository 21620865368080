import React from 'react';
import LunaPozaGrup from '../../static/images/LunaImages/luna-grupul.jpeg';

const TeamLuna = () => {
  return (
    <div className='luna-team-history-container d-flex flex-column justify-content-center align-items-center' >
        <div luna-team-history-title>
            <p className='luna-team-history-primary-heading'>How it started</p>
        
        </div>

        <div className='luna-team-history-box d-flex flex-row justify-content-center align-items-center mt-4 w-75'>
            <div className='luna-team-history-text-in-box d-flex flex-row justify-content-center align-items-center mt-4 w-100'>Project LUNA represented a challenge to apply our accumulated knowledge. LUNA is registered in the 30,000ft COTS class and features a supersonic rocket (2.05 Mach) utilizing solid motor propulsion. It was successfully launched on 23 of June in New Mexico (USA).
        </div>
            <div className='luna-team-history-image-in-box d-flex flex-row justify-content-center align-items-center'> <img src={LunaPozaGrup} alt="" /></div>
        </div>
    </div>
  )
}

export default TeamLuna