import React from "react";
import bannerImage from "../../static/images/Landing Page Assets/poza1_eva.png";
import icon1 from "../../static/images/Landing Page Assets/icon1.png";
import icon2 from "../../static/images/Landing Page Assets/icon2.png";
import icon3 from "../../static/images/Landing Page Assets/icon3.png";
import { FaTools, FaLocationArrow , FaUsers  } from "react-icons/fa";


const Home=()=> {
    // return(

    //  <div className="home-container">

    //     <div className="home-text-section">
    //         <h1 className="primary-heading">Project</h1>
    //         <h1 className="primary-heading-gradient">Eva</h1>
           
    //     </div>

    //     <div className="wrapper">
            
    //         <div className="text-box">
    //             <h1 className="secondary-heading">Mission Goals and Mission Key Events</h1>
    //             <div className="align-icon-paragraph">
    //                 <div className="icon"><img src={icon1} alt="" /></div>
                
    //                 <p className="paragraph-1">Transmission of real-time data to the ground</p> 
    //             </div>

    //             <div className="align-icon-paragraph">
    //                 <div className="icon"><img src={icon2} alt="" /></div>
    //                 <p className="paragraph-1">Reaching not more or less than 9000 m altitude</p>
    //             </div>
                
    //             <div className="align-icon-paragraph">
    //                 <div className="icon"><img src={icon3} alt="" /></div>
    //                 <p className="paragraph-1">Designing and optimizing of a rocket within a students team</p>
    //             </div>
            
    //         </div>
    //         <div className="home-image-container shadow">
    //             <img src={bannerImage} alt="" id="project_picture" />
    //         </div>
        
    //     </div>
          
    //  </div>

    return (
        <div className='eva-about-section-container'>
            <div className="eva-home-text-section">
                <h1 className="eva-primary-heading">Project</h1>
                <h1 className="eva-primary-heading-gradient">Eva</h1>
            </div>

            <div class="eva-box-home">
                
                

                <div className="eva-text-box">
                    <h1 className="eva-secondary-heading">Mission Goals and Mission Key Events</h1>
                    <div className="align-icon-paragraph">
                        <div className="eva-icon">
                            <FaTools color="white" className="eva-goals-icon" size="32"/>
                        </div>
                    
                        <p className="eva-paragraph-1">Transmission of real-time data to the ground</p> 
                    </div>

                    <div className="align-icon-paragraph">
                        <div className="eva-icon">
                            <FaLocationArrow color="white" size="32" className="eva-goals-icon"/>
                        </div>
                        <p className="eva-paragraph-1">Reaching not more or less than 9000 m altitude</p>
                    </div>
                    
                    <div className="align-icon-paragraph">
                        <div className="eva-icon">
                            <FaUsers color="white" size="32" className="eva-goals-icon"/>
                        </div>
                        <p className="eva-paragraph-1">Designing and optimizing of a rocket within a students team</p>
                    </div>
                
                </div>

                <div className="eva-home-image-container">
                    <img src={bannerImage} alt="" id="project_picture" />
                </div>
            
            </div>
    
        </div>
    )



};

export default Home;