import '../static/css/Eva.css';

import Home from '../Components/EvaComponents/Home';
import About from '../Components/EvaComponents/About';
import Team from '../Components/EvaComponents/Team';
import PCB from '../Components/EvaComponents/PCB';
import Components from '../Components/EvaComponents/Components';
import Navbar2sp from '../Components/Nav';
import { Footer2024 } from '../Components/Footer_2024';
import components from "../static/text/eva_components.js"
import VideoPlayer from '../Components/VideoPlayer.js';

function EvaPage() {
  return (
    <div className="App">
      <Navbar2sp/>
      <Home/>
      <VideoPlayer link="https://www.youtube.com/watch?v=xkN2pp6EfPg"/>
      <Team/>
      <Components components={components} />
      <PCB/>
      <Footer2024/>
    </div>
  );
}

export default EvaPage;
