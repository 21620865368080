import React from 'react';
import FlightImageLuna from '../../static/images/LunaImages/flight-luna.png'

const FlightStagesLuna = () => {
  return (
    <div className='luna-flight-stages-container d-flex flex-column justify-content-center align-items-center'>
        <h1 className='luna-flight-stages-title'>
            Flight stages
        </h1>
    <div className='luna-flight-stages-image'>
        <img src={FlightImageLuna} alt="" />
    </div>
    </div>
  )
}

export default FlightStagesLuna;