const luna_components = [

    
    {
        title:"Cone",
        description: "The nose cone has the main objective to reduce the atmosferic drag and it also carries the rocket payload.",
        image: null
    },

    {
        title:"Payload",
        description: "The payload main mission is to compare the radiation rate at 9km of the outside environment to the inside of a lead enclosure. The second mission is to determine the efficiency of a photovoltaic panel at 9km compared to the ground level.",
        image: null
    },

    {
        title:"Drogue parachute",
        description: "Drogue parachute will be open when the rocket hits the apogee in order to slow it down.",
        image: null
    },

    {
        title:"Main parachute",
        description: "Main parachute has the role of slowing down the rocket to a speed of aproximately 5-6 m/s.",
        image: null
    },


    {
        title:"Piston",
        description: "The piston is pushed up at the apogee and has the purpose of releasing the drogue parachute.",
        image: null
    },

    {
        title:"CO2 EJECTION SYSTEM",
        description: "The ejection generates the pressure needed to push the piston forward.",
        image: null
    },

    {
        title:"Electronics",
        description: "SRAD Flight computer has the ability to deploy 2 parachutes, local data logging with a 40Hz frequency, transmitting data in real time with a power of 100mW.",
        image: null
    },

    {
        title:"Airbrakes system",
        description: "The airbrakes system has the role of slowing down the rocket in order to stop at the target altitude. The airbrakes are also used as a coupler.",
        image: null
    },
   
    {
        title:"Lower fuselage",
        image: null,
        leftDescription:[
            {
                title:"Engineblock",
                description: "Engineblock connects the motor to the fuselage and provides motor retention.",
            },
            {
                title:"Centering rings",
                description: "Centering rings are used to obtain the motor positioning.",
            }
        ],
        rightDescription:[
           
            {
                title:"Fins rings",
                description: "Fins attachements rings are designed to connect the fins to the rocket.",
            },
            {
                title:"Fins",
                description: "Fins are used as a passive system to control the rocket.",
            },
        ]
    },
    
]

export default luna_components;