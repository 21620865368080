
import {React, useEffect, useState} from 'react';
import Cone from '../../static/images//LunaImages/con-modified.png';
import Airbrakes from '../../static/images/LunaImages/airbakes.png';
import Droug from '../../static/images/LunaImages/droug-modified.png';
import Electronics from '../../static/images/LunaImages/electronics-modified.png';
import MainParachute from '../../static/images/LunaImages/main_parachute.png';
import MotorAmpenages from '../../static/images/LunaImages/motor ampenages-modified.png';
import Payload from '../../static/images/LunaImages/payload.png';
import Recovery from '../../static/images/LunaImages/recovery-modified.png';
import Piston from '../../static/images/LunaImages/piston.png';
const ComponentsLuna = ({luna_components}) => {
  const [structuralComponents, setStructuralComponents] = useState([]);
  
  useEffect(()=> {
    luna_components.forEach(element => {
      switch(element.title) {
        case "Cone":
          element.image = Cone
          break;
        case "Payload":
          element.image = Payload
          break;
        case "Drogue parachute":
            element.image = Droug
          break;
        case "Main parachute":
          element.image = MainParachute
          break;
        case "Piston":
          element.image = Piston
          break;
        case "CO2 EJECTION SYSTEM":
          element.image = Recovery
          break;
        case "Electronics":
          element.image = Electronics
          break;
        case "Airbrakes system":
          element.image = Airbrakes
          break;
        case "Lower fuselage":
          element.image = MotorAmpenages 
          break;


          

        default:
          // code block
      } 
      setStructuralComponents(luna_components);
    });
  }, [])


return (
      <div className='d-flex flex-column justify-content-center align-items-center mt-5'>

        <div className='eva-work-section-top'>
            <h1 className='eva-primary-heading mb-5'>Components</h1>
        </div>
        
        <div className='d-flex flex-column align-items-center justify-content-center w-75'>

{luna_components.map((element, index) => {
  const imageComponent = element.image && <img src={element.image} alt="Column 2" style={{ maxWidth: '45%', height: 'auto' }} />
  if(element.description)
  {
    if(index % 2 == 0){
      return(
        <div className=' d-flex flex-row align-items-center justify-content-around w-100 mb-1 '>
          <div className='d-flex flex-column align-items-center justify-content-center col-3'>
            
          </div>

          <div className='d-flex flex-column align-items-center justify-content-center col-3'>
              { imageComponent}
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center col-3 position-relative'>
                      <div className=' d-flex flex-column align-items-center justify-content-center  '>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                    </div>
                  </div>
                );

              }else{
                return(
                  <div className=' d-flex flex-row align-items-center justify-content-around w-100 mb-1'>
                    <div className='d-flex flex-column justify-content-center align-items-center col-3 position-relative'>
                      <div className='d-flex flex-column align-items-center justify-content-center '>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3 '>
                      { imageComponent}
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                    </div>
                    
                  </div>
                );
                  
                
              }
            }
            else{
              {
                return (
                  <div className=' d-flex flex-row align-items-around justify-content-around w-100 mb-1'>

                    <div className='d-flex flex-column justify-content-around align-items-center col-3 position-relative'>
                      {element.leftDescription.map(d_element => {
                        return (
                          <div className=' d-flex flex-column align-items-center justify-content-center '>
                            <h2 className='eva-box-heading'>{d_element.title}</h2>
                            <p className='eva-box-paragraph'>{d_element.description}</p>
                          </div>
                        )
                      })}
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                      { imageComponent}
                    </div>

                    <div className='d-flex flex-column justify-content-around align-items-center col-3 position-relative'>
                      {element.rightDescription.map(d_element => {
                        return (
                          <div className=' d-flex flex-column align-items-center justify-content-center '>
                            <h2 className='eva-box-heading'>{d_element.title}</h2>
                            <p className='eva-box-paragraph'>{d_element.description}</p>
                          </div>
                        )
                      })}
                    </div>
                    
                  </div>
                  
                )
              }
            }

          })}
      
        </div>


    </div>
  )

}

export default ComponentsLuna;