import React from "react";
import "../static/css/Framework2sp/style.css";
import {FaFacebook, FaEnvelope, FaInstagram, FaLinkedin, FaRegCopyright} from "react-icons/fa"
import logoImg from '../static/images/logos/logo_cut.png';
import { Link } from "react-router-dom";

export const Footer2024 = (props) => {
    return(

        <section className="w-100 d-flex flex-column justify-content-center pb-0" id="footer_section">
            <hr className="w-100 horizontal-delimiter mb-5"/>
            <div className=" w-100 d-flex flex-row justify-content-between align-items-center">
                <div className="w-100 d-flex flex-row align-items-center" id="footer-content">
                    <div className="w-50 justify-content-start m-0 p-0" id="footer_search">
                        <div className="d-flex flex-row justify-content-start">
                            <img className="footer-logo" src={logoImg}  alt=""/>
                        </div>
                        <p className="mt-2" id="p-hero2">Join our newsletter to stay up to date on features and releases</p>
                        <div className="w-100 d-flex justify-content-start align-items-center" >
                            <input type="text" className="w-75 clasic-input border-white" placeholder="Enter your email" ></input>
                            <button className="ml-5 hero-button-transparent " id="footer-subscribe-news" >Send</button>
                        </div>
                        <p className="mt-3" id="p-hero2">By subscribing you agree to with our Privacy Policy and provide consent to receive updates from us.</p>
                    </div>

                    <div className="w-50 d-flex flex-row justify-content-between p-3" id="footer-links">
                        <div className="w-25 d-flex flex-column align-items-start ">
                            <h6 className="footer-links-categ mb-3">General</h6>
                            <h6 className="footer-links-page">
                                <Link to="/#bg-black">
                                    Home
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                <Link to="/AboutUs#bg-black" >
                                    About Us
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                <Link to="/team#bg-black" >
                                    Team
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                <Link to="/qube2space" >
                                    Qube2Space
                                </Link>
                            </h6>
                            <h6 className="footer-links-page" title="SOON!">
                                <Link to="/" >
                                    Events
                                </Link>
                            </h6>
                        </div>
                        <div className="w-25 d-flex flex-column align-items-start ">
                            <h6 className="footer-links-categ mb-3">General</h6>
                            <h6 className="footer-links-page">
                                <Link to="/#bg-black">
                                    Awards
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                <Link to="/project-eva">
                                    Project EVA
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                <Link to="/project-luna">
                                    Project LUNA
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                
                                <Link to="/project-luna">
                                    Support Us
                                </Link>
                            </h6>
                            <h6 className="footer-links-page">
                                <Link to="/voteazaConstient" className="mt-3">
                                    Vote
                                </Link>
                            </h6>
                        </div>
                        <div className="w-30 d-flex flex-column align-items-start justify-content-between">
                            <h6 className="footer-links-categ mb-3">Contact Us</h6>
                            <h6 className="footer-links-page">
                                <a href="https://www.facebook.com/Students2Space/" >
                                    <FaFacebook color="white"/> Facebook
                                </a>
                            </h6>
                            <h6 className="footer-links-page">
                                <a href="https://instagram.com/students2space" >
                                    <FaInstagram color="white"/> Instagram
                                </a>
                            </h6>
                            <h6 className="footer-links-page">
                                <a href="https://www.linkedin.com/company/students2space" >
                                    <FaLinkedin color="white"/> LinkedIn
                                </a>
                            </h6>
                            <h6 className="footer-links-page">
                                <a href="mailto: contact@students.2space.ro" >
                                    <FaEnvelope color="white"/> Email
                                </a>
                            </h6>
                        </div>

                        
                    </div>

                </div>
                
            </div>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.910776937712!2d26.0458088!3d44.4349924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201c4f8a217a7%3A0x39b9e6d086b107fa!2sCentrul%20CAMPUS!5e0!3m2!1sen!2sro!4v1711902030579!5m2!1sen!2sro" 
                loading="lazy" 
                className="footer-maps"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
            <hr className="w-100 horizontal-delimiter mt-5"/>
            <p className="ml-0 w-100 text-white text-start justify-content-center"><FaRegCopyright color="white"/> 2023 2Space. All rights reserved.</p>
        </section>
    );
}