const events = [
    {
        dateTitle: "1st November 2024",
        date:"1 November 2024 23:59",
        title: "QUBE2SPACE (ROBOFEST)",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "6th bJune 2024",
        date:"6 June 2024 23:59",
        title: "SPACEPORT AMERICA CUP",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "2nd June 2024",
        date:"2 June 2024 23:59",
        title: "SPACEFEST",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "1st January 2024",
        date:"1 January 2024 23:59",
        title: "PocketQube Satellite START",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "11th December 2023",
        date:"11 December 2023 18:00",
        title: "PocketQube Conference",
        description: "Pocket qube conference held in Glasgow hosted by Alba Orbital."
    },
    {
        dateTitle: "25th November 2023",
        date:"21 November 2023 23:59",
        title: "Space Tech Expo",
        description: "Europe's Largest B2B Space Event, the premier gathering for space professionals across the continent. "
    },
    {
        dateTitle: "1st November 2023",
        date:"1 November 2023 23:59",
        title: "QUBE2SPACE (ROBOFEST)",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "27th May 2023",
        date:"27 May 2023 23:59",
        title: "EELISA",
        description: "Student Scientific Competition at the 2nd Research-based Learning Symposia in Pisa"
    },
    {
        dateTitle: "18th  April 2023",
        date:"18 April 2023 23:59",
        title: "LAUNCH TEST",
        description: "Avionics test launch for project LUNA."
    },
    {
        dateTitle: "11th December 2022",
        date:"11 December 2022 18:00",
        title: "PocketQube Conference",
        description: "Pocket qube conference held in Glasgow hosted by Alba Orbital."
    },
    {
        dateTitle: "1st November 2022",
        date:"1 November 2022 23:59",
        title: "QUBE2SPACE (ROBOFEST)",
        description: "Pocket qube satellites competition for highschool enthusiastics."
    },
    {
        dateTitle: "5th July 2022",
        date:"5 July 2022 23:59",
        title: "LAUNCH OF EVA",
        description: "First EVA lauch at Smardan, Galati."
    },
    {
        dateTitle: "24th February 2022",
        date:"24 February 2022 23:59",
        title: "LAUNCHING TESTS",
        description: "Avionics test launch for project EVA."
    },
    {
        dateTitle: "17th October 2021",
        date:"17 October 2021 23:59",
        title: "EUROC - 2021",
        description: "Participated at EUROC in 2021."
    },
    {
        dateTitle: "2021",
        date:"24 February 2022 23:59",
        title: "Founded in 2021",
        description: "Marius Trifu founded this NGO in 2021."
    }

]

export default events;