import React from 'react'
import LunaImage from '../../static/images/LunaImages/Luna.jpg';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { GiBrain } from "react-icons/gi";

const ProjectLuna = () => {
  return (
    
        <div className='luna-home-text-section d-flex flex-column justify-content-center align-items-center'>
        
            <div className='luna-title'>
                <h1 className='luna-primary-heading'>Project</h1>
                <h1 className='luna-primary-heading-gradient'>Luna</h1>
            </div>
            <div class="luna-box-home">
                    <div className="luna-home-image-container ">
                        <img src={LunaImage} alt="" />
                    </div> 

                    <div className='luna-text-box'>
    
                        <h1 className="luna-secondary-heading">Mission Goals and Mission Key Events</h1>
                    <div className="align-icon-paragraph">
                     <div className="luna-icon"><img src={GiBrain} alt="" /></div>
                    <h1 className='luna-third-heading'>Knowledge Enhancement</h1>
                     <p className="luna-paragraph-1">With this project, we had the opportunity to broaden our knowledge, thus we are aiming for even higher milestones.</p> 
                 </div>

                 <div className="align-icon-paragraph">
                     <div className="luna-icon"><img src={TrendingUpIcon} alt="" /></div>
                     <h1 className='luna-third-heading'>Target</h1>
                     <p className="luna-paragraph-1">Our goal is to compete in the 30K solid COTS category, with a maximum velocity of 2.05Mach.</p>
                 </div>
                
                 <div className="align-icon-paragraph">
                     <div className="luna-icon"><img src={RocketLaunchIcon} alt="" /></div>
                     <h1 className='luna-third-heading'>LUNA's Flight Optimization</h1>
                     <p className="luna-paragraph-1">The maximum acceleration reached by LUNA should be 485.56ft/s^2, with a time to apogee of 39.8s.In the end, the flight time we are aiming for is 604s, and the ground hit velocity of 14.5ft/s.</p>
                </div>

                </div>
            </div>

               
            </div>
      
        
  )
}

export default ProjectLuna