import React from 'react';
import '../static/css/luna.css';
import ProjectLuna from '../Components/LunaComponents/ProjectLuna';
import TeamHistoryLuna from '../Components/LunaComponents/TeamHistoryLuna';
import ElectronicsLuna from '../Components/LunaComponents/ElectronicsLuna';
import FlightStagesLuna from '../Components/LunaComponents/FlightStagesLuna';
import ComponentsLuna from '../Components/LunaComponents/ComponentsLuna';
import TeamLuna from '../Components/LunaComponents/TeamLuna';
import Navbar2sp from '../Components/Nav';
import { Footer2024 } from '../Components/Footer_2024';
import luna_components from "../static/text/luna_components";

const LunaPage = () => {
  return (
    <div className="App">
      <Navbar2sp/>
      <ProjectLuna/>
      <TeamHistoryLuna/>
      <TeamLuna/>
      <ElectronicsLuna/>
      <ComponentsLuna luna_components={luna_components}/>
      <FlightStagesLuna/>
      <Footer2024/>
    </div>
  )
}

export default LunaPage