import {React, useEffect, useState} from 'react';
import Cone from '../../static/images/Landing Page Assets/con-eva.png';
import Igniter from '../../static/images/Landing Page Assets/igniter-modified.png';
import Electronica from '../../static/images/Landing Page Assets/modul-electronica-modified.png';
import MAF from '../../static/images/Landing Page Assets/motor-ampenaje-flansamotor.png';
import Parasuta from '../../static/images/Landing Page Assets/parasuta-modified.png';
import Payload from '../../static/images/Landing Page Assets/payload.png';
import Piston from '../../static/images/Landing Page Assets/piston.png';
import Ampenaje from '../../static/images/Landing Page Assets/motor-ampenaje-flansamotor.png';
import { colors } from '@mui/material';
import { CenterFocusWeakTwoTone } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
const Components = ({components}) => {
  const [structuralComponents, setStructuralComponents] = useState([]);
  
  useEffect(()=> {
    components.forEach(element => {
      switch(element.title) {
        case "Cone":
          element.image = Cone
          break;
        case "Payload":
          element.image = Payload
          break;
        case "Main parachute":
          element.image = Parasuta
          break;
        case "Second piston":
          element.image = Piston
          break;
        case "PCB":
          element.image = Electronica
          break;
        case "First piston":
          element.image = Piston
          break;
        case "Drogue parachute":
          element.image = Parasuta
          break;
        case "Lower fuselage":
          element.image = Ampenaje 
          break;


          

        default:
          // code block
      } 
      setStructuralComponents(components);
    });
  }, [])

    return (
      <div className='d-flex flex-column justify-content-center align-items-center mt-5'>

        <div className='eva-work-section-top'>
            <h1 className='eva-primary-heading mb-5'>Components</h1>
        </div>

      {/* <div className='eva-flex-container'> 
        <div className='eva-flex-box wrap shadow'>
                  <h2 className='eva-flex-box-heading'>Cone</h2>
                The nose cone has the main objective to reduce the atmosferic drag and it also carries the rocket payload.
                
        </div>
        <div className='eva-flex-image'>
        <img src={Cone} alt="Column 2" style={{ maxWidth: '70%', height: 'auto' }} />
        </div>
        <div className='eva-flex-box wrap shadow'>
          Box3
        </div>
      
      </div> */}
        
        {/* <table>
          <tbody>
            <tr className='eva-row-1'>
              <td className='col-4'>
                <div className='wrap shadow'>
                  <h2 className='eva-box-heading'>Cone</h2>
                  <p className='eva-box-paragraph'>The nose cone has the main objective to reduce the atmosferic drag and it also carries the rocket payload.</p>
                </div>
              </td>
    
              <td className='col-4'>
                <img src={Cone} alt="Column 2" style={{ maxWidth: '100%', height: 'auto' }} />
              </td>
    
              <td className='col-4'>
                <div className='wrap shadow '>
                  <h2 className='eva-box-heading'>Payload</h2>
                  <p className='eva-box-paragraph'>Payload consists of a custom-made PCB that will read data from multiple sensors of the same type in order to compare their performance.</p>
                </div>
              </td>
            </tr>

            <tr className='row-2'>
              <td>
                <div className='wrap shadow'>
                  <h2 className='eva-box-heading'>Main parachute</h2>
                  <p className='eva-box-paragraph'>Main parachute has the role of slowing down the rocket to a speed of aproximately 5-6 m/s.</p>
                </div>
              </td>
    
              <td className='column-2'>
                <img src={Payload} alt="Column 2" style={{ maxWidth: '100%', height: 'auto' }} />
              </td>
    
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>The second piston</h2>
                  <p className='box-paragraph'>The second piston will push up the main parachute which will open at an altitude of 400 m.</p>
                </div>
              </td>
            </tr>

            <tr className='row-3'>
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Third PCB</h2>
                  <p className='box-paragraph'>The third PCB controls the timpe when the pistons are pushed up and locally stores the telemetry data on a microSD.</p>
                </div>
              </td>
    
              <td className='column-2'>
                <img src={Parasuta} alt="Column 2" style={{ maxWidth: '100%', height: 'auto' }} />
              </td>
    
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Second PCB</h2>
                  <p className='box-paragraph'>The second PCB is a certified hardware that is used as a back-up system for storing data and telemetry.</p>
                </div>
              </td>
            </tr>

            <tr className='row-4'>
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>First PCB</h2>
                  <p className='box-paragraph'>The first PCB is used to send data in real time to the ground station and locally saves video tapes from 2 cameras on a microSD card.</p>
                </div>
              </td>
    
              <td className='column-2'>
                <img src={Piston} alt="Column 2" style={{ maxWidth: '100%', height: 'auto' }} />
              </td>
    
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>First piston</h2>
                  <p className='box-paragraph'>First piston is pushed up at the apogee and has the purpose of releasing the parachute.</p>
                </div>
              </td>
            </tr>

            <tr className='row-5'>
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Drogue parachute</h2>
                  <p className='box-paragraph'>Drogue parachute will be open when the rocket hits the apogee in order to slow it down.</p>
                </div>
              </td>
    
              <td className='column-2'>
                <img src={Electronica} alt="Column 2" style={{ maxWidth: '70%', height: 'auto'}} />
              </td>
    
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Motor stopper</h2>
                  <p className='box-paragraph'>Motor stopper connects the motor to the fuselage and provides motor retention.</p>
                </div>
              </td>
            </tr>

            <tr className='row-6'>
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Motor</h2>
                  <p className='box-paragraph'>The motor has a total impulse of 17900 N-s and will burn for 7 seconds.</p>
                </div>
              </td>
    
              <td>
                <img src={Parasuta} alt="Column 2" style={{ maxWidth: '100%', height: 'auto'}} />
              </td>
    
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Centering rings</h2>
                  <p className='box-paragraph'>Centering rings are used to obtain the motor positioning.</p>
                </div>
              </td>
            </tr>

            <tr className='row-7'>
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'> Fins rings</h2>
                  <p className='box-paragraph'>Fins attachements rings are designed to connect the fins to the rocket.</p>
                </div>
              </td>
    
              <td>
                <img src={MAF} alt="Column 2" style={{ maxWidth: '100%', height: 'auto'}} />
              </td>
    
              <td>
                <div className='wrap shadow'>
                  <h2 className='box-heading'>Fins</h2>
                  <p className='box-paragraph'>Fins are used as a passive system to control the rocket.</p>
                </div>
              </td>
            </tr>

          </tbody>
        </table> */}
    
        {/* <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className=' d-flex flex-row align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-center justify-content-center col-4'>
              <h2 className='eva-box-heading'>Cone</h2>
              <p className='eva-box-paragraph'>The nose cone has the main objective to reduce the atmosferic drag and it also carries the rocket payload.</p>
            </div>

            <div className='d-flex flex-column align-items-center justify-content-center col-4'>
                <img src={Cone} alt="Column 2" style={{ maxWidth: '50%', height: 'auto' }} />
            </div>

            <div className='d-flex flex-column align-items-center justify-content-center col-4'>
              <h2 className='eva-box-heading'>Payload</h2>
              <p className='eva-box-paragraph'>Payload consists of a custom-made PCB that will read data from multiple sensors of the same type in order to compare their performance.</p>
            </div>
            
          </div>
        </div> */}

        {/* <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className=' d-flex flex-row align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-center justify-content-center col-4'>
              <h2 className='eva-box-heading'>Main parachute</h2>
              <p className='eva-box-paragraph'>Main parachute has the role of slowing down the rocket to a speed of aproximately 5-6 m/s.</p>
            </div>

            <div className='d-flex flex-column align-items-center justify-content-center col-4'>
                <img src={Payload} alt="Column 2" style={{ maxWidth: '50%', height: 'auto' }} />
            </div>

            <div className='d-flex flex-column align-items-center justify-content-center col-4'>
              <h2 className='eva-box-heading'>The second piston</h2>
              <p className='eva-box-paragraph'>The second piston will push up the main parachute which will open at an altitude of 400 m.</p>
            </div>
            
          </div>
        </div> */}

        <div className='d-flex flex-column align-items-center justify-content-center w-75'>

          {components.map((element, index) => {
            const imageComponent = element.image && <img src={element.image} alt="Column 2" style={{ maxWidth: '45%', height: 'auto' }} />
            if(element.description)
            {
              if(index % 2 == 0){
                return(
                  <div className=' d-flex flex-row align-items-center justify-content-around w-100 mb-1 '>
                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                      
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                        { imageComponent}
                    </div>

                    {/* <div className=' column-h-100 d-flex flex-column justify-content-around align-items-center h-100 col-3'>
                      <div className=' d-flex flex-column align-items-center justify-content-center row-4 '>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                      <div className=' d-flex flex-column align-items-center justify-content-center row-4'>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                      <div className=' d-flex flex-column align-items-center justify-content-center row-4 '>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                    </div> */}
                    <div className='d-flex flex-column justify-content-center align-items-center col-3 position-relative'>
                      <div className=' d-flex flex-column align-items-center justify-content-center  '>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                    </div>
                  </div>
                );

                  
                
              }else{
                return(
                  <div className=' d-flex flex-row align-items-center justify-content-around w-100 mb-1'>
                    <div className='d-flex flex-column justify-content-center align-items-center col-3 position-relative'>
                      <div className='d-flex flex-column align-items-center justify-content-center '>
                        <h2 className='eva-box-heading'>{element.title}</h2>
                        <p className='eva-box-paragraph'>{element.description}</p>
                      </div>
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3 '>
                      { imageComponent}
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                    </div>
                    
                  </div>
                );
                  
                
              }
            }
            else{
              {
                return (
                  <div className=' d-flex flex-row align-items-around justify-content-around w-100 mb-1'>

                    <div className='d-flex flex-column justify-content-around align-items-center col-3 position-relative'>
                      {element.leftDescription.map(d_element => {
                        return (
                          <div className=' d-flex flex-column align-items-center justify-content-center '>
                            <h2 className='eva-box-heading'>{d_element.title}</h2>
                            <p className='eva-box-paragraph'>{d_element.description}</p>
                          </div>
                        )
                      })}
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                      { imageComponent}
                    </div>

                    <div className='d-flex flex-column justify-content-around align-items-center col-3 position-relative'>
                      {element.rightDescription.map(d_element => {
                        return (
                          <div className=' d-flex flex-column align-items-center justify-content-center '>
                            <h2 className='eva-box-heading'>{d_element.title}</h2>
                            <p className='eva-box-paragraph'>{d_element.description}</p>
                          </div>
                        )
                      })}
                    </div>
                    
                  </div>
                  
                )
              }
            }

          })}
      
        </div>

    </div>

    );
}


export default Components;