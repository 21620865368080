import React from 'react';
import LunaElectronics1 from '../../static/images/LunaImages/electronica-luna.png';
import LunaElectronics2 from '../../static/images/LunaImages/electro-luna.png';

const ElectronicsLuna = () => {
    const PCBInfoData=[
        {
            heading: 'Electronics',
            text: 'SRAD Flight computer, designed to function independently, with the ability to deploy 2 parachutes, local data logging with a 40Hz frequency, transmitting data in real time with a power of 100mW',
        },
        {
            image:LunaElectronics1
        },
        {
            image:LunaElectronics2
        }
    ]
  return (
    <div className='luna-work-section-wrapper'>
       
        <div className='luna-work-section-bottom'>
            {
                PCBInfoData.map((data) => (
                    <div className='luna-work-section-info'>
                        <div className='luna-info-boxes-img-container'>
                            {data.heading && <h1 className='luna-electronics-heading'>{data.heading}</h1>}
                            {data.text && <p>{data.text}</p>}
                            <img src={data.image} alt="" />
                        </div>
                    </div>
                )
                )
            }
        </div>

    </div>
  )
}

export default ElectronicsLuna;