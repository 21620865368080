import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandymanIcon from '@mui/icons-material/Handyman';
import DescriptionIcon from '@mui/icons-material/Description';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import "../static/css/Timeline.css";
//import events from "../static/text/about_us_timeline.js" 



export default function CustomizedTimeline({main_color, secondary_color, not_avaible_color, events, colorTillPasses}) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [eventsData, setEventsData] = useState([]);
  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 60000);
    setEventsData(events);
    return () => clearInterval(timer);
  }, []);

  const isPastEvent = (eventDateStr) => {
    const eventDate = new Date(eventDateStr);
    return currentTime > eventDate; 
  }


  return (
    <ul className='timeline-ul'>
      {eventsData.map((element, index) => {

        var firstColor = isPastEvent(element.date) ? (colorTillPasses ? not_avaible_color : main_color ) : (colorTillPasses ? main_color : not_avaible_color );
        var secondColor = isPastEvent(element.date) ? (colorTillPasses ? not_avaible_color : secondary_color ) : (colorTillPasses ? secondary_color : not_avaible_color ); 
        
        if(index % 2 == 0){

          return(
            // <li style={{"--accent-color":"#6B0FF6"}} className='timeline-li'>
            <li style={{"--accent-color": firstColor}} className='timeline-li'>
              <div className="date" style={{"--first-color" :firstColor, "--second-color":secondColor  }} >
                {element.dateTitle}
              </div>
              <div className="glowing-button-margin" style={{"--bg-revert": secondColor, "--accent-color": firstColor}}>
                <div className="title">{element.title}</div>
                <div className="descr">
                  {element.description}
                </div>
              </div>
            </li>
          );

          
        }else{
          return(
            <li style={{"--accent-color": secondColor}} className='timeline-li'>
              <div className="date" style={{"--first-color" :firstColor, "--second-color":secondColor  }} >
                {element.dateTitle}
              </div>
              <div className="glowing-button-margin" style={{"--bg-revert": firstColor, "--accent-color": secondColor}}>
                <div className="title">{element.title}</div>
                <div className="descr">
                  {element.description}
                </div>
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
}