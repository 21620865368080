import React, { useState, useEffect } from "react";
import Navbar from "../Components/Nav";
import Footer from "../Components/Footer";
import {Footer2024} from '../Components/Footer_2024';
import "../static/css/AboutUS.css";
import Main from "../static/images/backgrounds/vodafone.JPG";
import logoImg from "../static/images/logos/logo.png"
import campus_entrance from "../static/images/AboutUS/campus_entrance.svg"
import campus_logo_hires from "../static/images/Sponsors/campus_logo_hires.png"
import euroc_prep from "../static/images/AboutUS/euroc_prep.png"
import eurog_logo from "../static/images/AboutUS/euroc_v2-white 1.png"
import Timeline from "../Components/Timeline";
import events from "../static/text/about_us_timeline.js" 

const AboutUs = (props) => {
 
  return (
      <div className="space-body">
        <Navbar/>
        <div className="sp-header" id="about-us-header">
          <div className="home-content">
              <h1 className="header-main-title text-uppercase">ABOUT US</h1>
              <h2 className="header-second-title text-uppercase">Expanding </h2>
              <h2 className="header-second-title text-uppercase">possibilities</h2>

          </div>
        </div>

        {/* ==== WORK ENVIROMENT ==== */}
        <section className='bg-transparent d-flex flex-column justify-content-center align-items-center' id="work_enviroment">
          <h2 className="header-second-title text-white">Work enviroment</h2>

          <div className="d-flex flex-row justify-content-center align-items-center margin-top-5 w-85 w-1200-100 " id="campus_image_div">

            <div className="d-flex justify-content-start align-items-center margin-top-5 w-75" >
              <img alt="Some photo..." src={campus_entrance} className="d-block w-100 z2" />

            </div> 

            <div className="d-flex justify-content-center align-items-center w-100 overflow-visible">
              <div className="d-flex flex-column justify-content-center align-items-center overflow-visible w-100 h-100 z-2" >
                <img alt="Some photo..." src={campus_logo_hires}  className="d-block w-50 margin-bottom-5 z2"/>
                <p className="text-white text-start w-75 margin-top-5 margin-bottom-5 h4 p-0">
                  You can find us day and night in the CAMPUS Institute, fully committed to our mission,  working hard to obtain the best results. 
                </p>
                <p className="text-white text-start w-75 margin-top-5 margin-bottom-5 h4 p-0">
                  Specifically, this initiative supports volunteers in building and launching various rocket models.
                </p>

              </div>
            </div>

          </div>

        </section>

        {/* ==== COMPETITION ==== */}
        {/* <section className='bg-transparent d-flex flex-column justify-content-center align-items-center'>
          <h2 className="header-second-title text-white">Competitions</h2>

          <div className="d-flex flex-row justify-content-center align-items-center margin-top-5 w-85 w-1200-100 " >

            <div className="d-flex justify-content-start align-items-center margin-top-5 w-75" >
              <img alt="Some photo..." src={euroc_prep} className="d-block w-100 z2" />

            </div> 
            
            <div className="d-flex justify-content-center align-items-center w-100 ">
              <div className="d-flex flex-column justify-content-center align-items-center overflow-visible w-100 h-100 z-2" >
                <div className="d-flex w-25 margin-bottom-5" id="euroc_image">
                  <img alt="Some photo..." src={eurog_logo}  className="d-block w-100" />
                </div>
                <p className="text-white text-start w-75 margin-top-5 margin-bottom-5 h4 p-0">
                  The European Rocketry Challenge – EuRoC is the first rocket launch competition for European university teams. The Portuguese Space Agency created the competition in 2020 and has since gathered teams from various European countries in the Alentejo region. Based on constant and sustained growth, the 2023 edition of the competition has the support of the Portuguese Army and the Municipality of Constância. 
                </p>
              </div>
            </div>

          </div>

          <hr className="w-75 horizontal-delimiter margin-bottom-5 margin-top-5"/> 

          <div className="d-flex flex-row justify-content-center align-items-center margin-top-5 w-85 w-1200-100 " >

            <div className="d-flex justify-content-start align-items-center margin-top-5 w-75" >
              <img alt="Some photo..." src={euroc_prep} className="d-block w-100 z2" />
            </div> 
            
            <div className="d-flex justify-content-center align-items-center w-100 ">
              <div className="d-flex flex-column justify-content-center align-items-center overflow-visible w-100 h-100 z-2" >
                <div className="d-flex w-25 margin-bottom-5" id="euroc_image">
                  <img alt="Some photo..." src={eurog_logo}  className="d-block w-100" />
                </div>
                <p className="text-white text-start w-75 margin-top-5 margin-bottom-5 h4 p-0">
                  The European Rocketry Challenge – EuRoC is the first rocket launch competition for European university teams. The Portuguese Space Agency created the competition in 2020 and has since gathered teams from various European countries in the Alentejo region. Based on constant and sustained growth, the 2023 edition of the competition has the support of the Portuguese Army and the Municipality of Constância. 
                </p>
              </div>
            </div>

          </div>

        </section> */}

        {/* === Timeline  */}
        <section className="d-flex flex-column justify-content-center align-items-center " >  
          <h6 className="h6-home">History</h6>
          <h2 id="heading" className="text-white text-bold hover-glowing">Events timeline</h2>
          <h6 className="h6-home margin-bottom-5">Explore our past and future</h6>

          <Timeline main_color={"#6B0FF6"}  events ={events} secondary_color={"#FF5F56"} not_avaible_color={"#808080"} />
          
        </section>
        <Footer2024/>
    </div>
  )
}
    
    
    export default AboutUs;
    