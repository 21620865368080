import { Class } from '@mui/icons-material';
import React from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = (props) => {
    return(
        <div className='d-flex justify-content-center align-items-center'>
            <ReactPlayer url={props.link} playing={true} playsinline={true} loop={true}  />
        </div>
    );
}

export default VideoPlayer;