import {FaFacebook, FaEnvelope, FaInstagram, FaLinkedin, FaGooglePlus} from "react-icons/fa"

const  MemberCard = ({image, name, role, col, email, instagram, width}) => {
    return(
      <div className={"col-" + (col ? col : "6") + " d-flex justify-content-center align-items-center"} id="members-row" height="" >
         <div className={"col-12 d-flex justify-content-center"}>
              <div className="our-team d-flex justify-content-center">
                  <img src={image} alt=""/>
                  <ul className="social">
                      <li><a href={instagram ? instagram: "#"} className="fab fa-instagram"><FaInstagram className="w-75"/></a></li>
                      <li><a href={email ? email : "#"} className="fab fa-linkedin"><FaEnvelope className="w-75"/></a></li>
                  </ul>
                  <div className="team-content">
                      <h3 className="title">{name}</h3>
                      <span className="post">{role}</span>
                  </div>
              </div>
          </div>
      </div>
    )
  }

  export default MemberCard;