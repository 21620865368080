import React, {useState, useEffect} from "react";

import Navbar from '../Components/Nav';
import Footer from '../Components/Footer';
import MemberCard from "../Components/MemberCard";

import imgCornerLeft from '../static/images/components/output-onlinegiftools.gif'
import logoImg from "../static/images/logos/logo.png"
import "../static/css/vote.css";

import { Footer2024 } from "../Components/Footer_2024";

const Vote = (props) => {
    return (
        <div className="App" id="vote-app">
            <Navbar smth={[1, 2, 3]}/>
            <header className="App-header" id="app-header-vote">
                <div className='overlay-header-bg'></div>
                <img className="title-logo mt-5" src={logoImg}  alt=""/>
                {/* <img className="title-logo" src={UPB_logo}  alt="" id="logo-upb"/> */}
                <div className="w-75 d-flex" id="q2s-title">
                    <h1 id="blend-mode-title">Voteaza constient!</h1>
                </div>
                <div className="w-50 d-flex" id="q2s-title">
                    <h2 id="blend-mode-title">Ce este consiliul facultății?</h2>
                </div>
                <div className="w-75 d-flex" id="q2s-title">
                    <h2 id="blend-mode-title">Consiliul Facultății este cea mai înaltă structură de conducere din facultate</h2>
                </div>
                <section id="vote-list-section" className="w-100 d-flex flex-row">
                    <ul id="concil-resposibilities" className="mt-5">
                        <li id="concil-resposibilities">Voturile studenților, din consiliul facultății, au o putere decizională în proporție de 30%</li>
                        <li id="concil-resposibilities">Propune condițiile de admitere pentru programele de licență, master, doctorat</li>
                        <li id="concil-resposibilities">Aprobă transferul studenților între secțiile facultății sau interuniversitar</li>
                        <li id="concil-resposibilities">Toate deciziile luate în cadrul facultății trebuie aprobate de consiliu</li>
                        <li id="concil-resposibilities">Aprobă comisiile pentru examenele de finalizare a studiilor</li>
                        <li id="concil-resposibilities">Elaborează și/sau avizează planurile de învățământ</li>
                        <li id="concil-resposibilities">Evaluează periodic activitatea didactică</li>
                        <li id="concil-resposibilities">Propune sumele pentru școlarizare</li>
                    </ul>
                </section>
                {/* <div className='bg-white' id="bg-white"></div> */}

                {/* <div className="w-75 d-flex mt-5" style ={{zIndex: 1000, color:"white"}} id="q2s-title">
                    <span id="vote-span-title">Noi o sustinem pe Alexandra. Tu ?</span>
                </div>

                <section style ={{zIndex: 1000, color:"white"}} className='w-100 d-flex flex-column justify-content-center align-items-center bg-transparent mt-5 mb-5'>
                    
                    <MemberCard image={AlexandraP} role={"Volunteer"} name={"Alexandra Păltinuș"}  email={"mailto: alexandra_paltinus@yahoo.ro"} instagram={"https://instagram.com/_alexandrap_3?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"}/>
                </section> */}
            </header>
            <div className='About w-100 d-flex flex-column justify-content-center align-items-center'>

                
                <Footer2024/>
            </div>
        </div>
    )
}
export default Vote;