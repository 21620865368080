const components = [

    
    {
        title:"Cone",
        description: "The nose cone has the main objective to reduce the atmosferic drag and it also carries the rocket payload.",
        image: null
    },

    {
        title:"Main parachute",
        description: "Main parachute has the role of slowing down the rocket to a speed of aproximately 5-6 m/s.",
        image: null
    },

    {
        title:"Payload",
        description: "Payload consists of a custom-made PCB that will read data from multiple sensors of the same type in order to compare their performance.",
        image: null
    },
    {
        title:"Second piston",
        description: "The second piston will push up the main parachute which will open at an altitude of 400 m.",
        image: null
    },
    {
        title:"PCB",
        image: null,
        leftDescription:[
            {
                title:"Second PCB",
                description: "The second PCB is a certified hardware that is used as a back-up system for storing data and telemetry.",
            },
        ],
        rightDescription:[
            
            {
                title:"Third PCB",
                description: "The third PCB controls the timpe when the pistons are pushed up and locally stores the telemetry data on a microSD.",
            },
            {
                title:"First PCB",
                description: "The first PCB is used to send data in real time to the ground station and locally saves video tapes from 2 cameras on a microSD card.",
            },
        ]
    },


    {
        title:"First piston",
        description: "First piston is pushed up at the apogee and has the purpose of releasing the parachute.",
        image: null
    },
    {
        title:"Drogue parachute",
        description: "Drogue parachute will be open when the rocket hits the apogee in order to slow it down.",
        image: null
    },
    {
        title:"Lower fuselage",
        image: null,
        leftDescription:[
            {
                title:"Motor",
                description: "The motor has a total impulse of 17900 N-s and will burn for 7 seconds.",
            },
            {
                title:"Centering rings",
                description: "Centering rings are used to obtain the motor positioning.",
            }
        ],
        rightDescription:[
            {
                title:"Motor stopper",
                description: "The motor has a total impulse of 17900 N-s and will burn for 7 seconds.",
            },
            {
                title:"Fins rings",
                description: "Fins attachements rings are designed to connect the fins to the rocket.",
            },
            {
                title:"Fins",
                description: "Fins are used as a passive system to control the rocket.",
            },
        ]
    },
    
]

export default components;