import React from 'react'
import TeamImage from '../../static/images/Landing Page Assets/imagine2-eva.png';

const Team = () => {
return <div className='eva-about-section-container'>

            <div className='eva-about-section-text-container d-flex flex-column justify-content-center align-items-center '>
                <div className='eva-home-text-section'>
                    <h1 className='eva-primary-heading-x'>Summary of the project</h1>
                    <h1 className='eva-primary-heading-x-gradient'>history</h1>
                </div>

                <div class="eva-box d-flex flex-row justify-content-center align-items-center mt-4 w-75">
                    
                    <div class="eva-text-box w-50">
                        <p className='eva-paragraph-2'>Our team was founded in the summer of 2019 by several students from University of Politehnica, Bucharest.</p>
                        <p className='eva-paragraph-2'>Eva is just one of the projects that our team successfully developed. Our mission is to provide orbital launching services for nanosatellites through our start-up, 2Space.</p>
                    </div>
                    
                    <div className="eva-team-image-container w-50">
                        <img src={TeamImage} alt="" className='w-100' />
                    </div>
                    
                </div>
            </div>
        </div>

  
}

export default Team;