import React from "react";
import { useState } from "react";

const Schedule =  (props) => {

    return (
        <>
            <div className="sp2-schedule-container mt-5">
                <div className="d-flex flex-column align-items-center "  >
                    <h1 clasName="sp2-section-title ">Schedule</h1>
                    <h1 clasName="sp2-section-title ">- - -</h1>
                </div>    
                <div className="d-flex flex-row justify-content-start "  >
                    <div className="d-flex flex-column w-50 " id="c8760C1">
                        <div className="sp2-schedule-container-header">
                            <h1 className="sp2-schedule-container-title">Thursday 2nd November</h1>
                        </div>
                        <div className="sp2-schedule-container-body">
                            <ul className="sp2-schedule-ul ">
                                 <li>
                                    09.00 - 13.00 : Arrival of Participants - Regie Complex(no exceptions)
                                 </li>
                                 <li>
                                    16.00 - 17.00 : General Briefing - Safety Training - UNSTPB Library
                                </li>
                                <li>
                                    16.00 - 20.00 Feedback (Anexa 1) - UNSTPB Library
                                </li>
                                <li>
                                    20.00 - Social Activities / Free Time
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-end "  >
                    <div className="d-flex flex-column w-50 " id="c5A15C1">
                        <div className="sp2-schedule-container-header">
                            <h1 className="sp2-schedule-container-title">Friday 3rd November</h1>
                        </div>
                        <div className="sp2-schedule-container-body">
                            <ul className="sp2-schedule-ul">
                                <li>
                                    09.00 - 13.00  :  Workshop - UNSTPB Library
                                </li>
                                <li>
                                     13.00 - 14.30 :  Lunch Break - UNSTPB Cafeteria
                                </li>
                                <li>
                                    16.00 - 20.00 :  Workshop - UNSTPB Library
                                </li>
                                <li>
                                    18.00 - 19.00 Opening Ceremony - AULA of UNSTPB
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-start "  >
                    <div className="d-flex flex-column w-50 " id="c8760C1">
                        <div className="sp2-schedule-container-header">
                            <h1 className="sp2-schedule-container-title">Saturday 4th November</h1>
                        </div>
                        <div className="sp2-schedule-container-body">
                            <ul className="sp2-schedule-ul">
                                 <li>
                                    09.00 - 14.00 Workshop - Expo Zone Hall
                                 </li>
                                 <li>
                                    14.00 -17.00 Jury Evaluation - Expo Zone Hall
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-end "  >
                    <div className="d-flex flex-column w-50 " id="c5A15C1">
                        <div className="sp2-schedule-container-header">
                            <h1 className="sp2-schedule-container-title">Sunday 5th November</h1>
                        </div>
                        <div className="sp2-schedule-container-body">
                            <ul className="sp2-schedule-ul">
                                 <li>
                                    09.00 - 11.00 : Jury Evaluation - Expo Zone Hall
                                 </li>
                                 <li>
                                    14.00 : Award Ceremony - Expo Zone Hall
                                </li>
                                <li>
                                    16.00 : Closing Ceremony - AULA of UNSTPB
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Schedule;