import React, { useState, useEffect } from "react";
import Navbar from "../Components/Nav";
import Footer from "../Components/Footer";

import Q2SSchedule from "../Components/Q2SSchedule.js";
import Schedule from "../Components/Schedule";

import "../static/css/AboutUS.css";
import "../static/css/qube2space.css"
import Main from "../static/images/backgrounds/vodafone.JPG";
import logoImg from "../static/images/logos/logo.png"
import Modal from 'react-bootstrap/Modal';
import pdfRO from '../static/docs/rulebook_ro.pdf';
import pdfEN from '../static/docs/rulebook_en.pdf';
import { FaWindowClose } from "react-icons/fa";
import MemberCard from "../Components/MemberCard";
import MultiActionAreaCard from "../Components/CardComponent";
import Enachescu from "../static/images/jury/enachescu.jpeg";
import Constantinescu from "../static/images/jury/constantinescu.jpeg";
import Rosner from "../static/images/jury/rosner.jpeg";
import Catalin from "../static/images/Poze editate/Chelmus Catalin.jpg";
import idea from "../static/images/Qube2space/idea.png";
import construct from "../static/images/Qube2space/construct.png";
import CustomizedTimeline from "../Components/Timelinetest";
import UPB_logo from '../static/images/Sponsors/LOGO_UPB_205_3.3_ro.png'
import LogoETTI from '../static/images/Sponsors/LogoETTI.png'
import CAMPUS_logo from '../static/images/Sponsors/campus_logo_hires.png'
import Sponsor from '../Components/Sponsor.js'
import ASFIA_logo from '../static/images/Sponsors/ASFIA.png'
import FIA_logo from '../static/images/Sponsors/FIA_logo_hires.png'
import ACS_logo from '../static/images/Sponsors/acs_logo.png'
import VODAFONE_logo from '../static/images/Sponsors/vodafone_logo_hires.png'
import PRAGMATIC_logo from '../static/images/Sponsors/logo-pragmatic-play.png'
import Costoiu from "../static/images/jury/costoiu.jpeg";
import Ionescu from "../static/images/jury/Ionescu.jpg";
import Crunțeanu from "../static/images/jury/daniel-crunteanu-secretar-stat.jpg";
import Mihnea from "../static/images/jury/Mihnea_moisescu-1-201x300.jpg";
import CountDown from "../Components/CountDown";
import q2s2024 from "../static/images/Qube2space/q2s2024.jpeg"
import Caius from "../static/images/jury/Caius.jpeg"
import Tchibo from "../static/images/Sponsors/Tchibo.png"
import Kandia from "../static/images/Sponsors/Kandia.png"
import Monster from "../static/images/Sponsors/Monster.png"
import Ionut from "../static/images/jury/Ionut-Gabriel.jpeg"
import events from "../static/text/q2s_2024_plans.js"
import Timeline from "../Components/Timeline";
import { Footer2024 } from "../Components/Footer_2024.js";

let descriptions = [
    {
        "d1": "S.l. dr. Marius Enachescu",
        "d2": "Faculty of Electronics, Telecommunications and Information Technology",
        "d3": "Acquiring his engineering and PhD degrees from Politehnica University of Bucharest and Delft University of Technology respectively, he now serves as an assistant professor in Bucharest and leads the analog design team at Microchip. His research, award-winning publications, and HiPEAC scholarship focus on nano-devices, low-power systems, and sensor-based circuits.",

    },
    {
        "d1": "Dr. Engr. Cristian Constantinescu",
        "d2": "Faculty of Aerospace Engineering",
        "d3": "Cristian Constantinescu, head of thesis research at POLITEHNICA University's Faculty of Aerospace Engineering, focuses on avionics, air vehicle control, and engineering programming languages. He leads the Satellite Systems and Subsystems course and is a member of the Royal Institute of Navigation.",
    },
    {
        "d1": "Master's Engineer Ionuț-Gabriel Oțelea",
        "d2": "Faculty of Automatic Control and Computer Science Engineering",
        "d3": "Ionut Otelea is a teaching assistant (electronics and microprocessors design) at the Faculty of Automation and Computers Science at POLITEHNICA University of Bucharest. He has cofounded the Tire2Tire start-up based on an autonomous robot for automatic tire wear detection. Ionut is currently working at the Ground Transportation Division of Thales Romania and is studying at Advanced Computers Architecture master program.",
    },
    {
        "d1": "Mihnea Costoiu",
        "d2": "Rector of the Politechnica University of Bucharest",
        "d3": "As the rector of the Politehnica University of Bucharest, Mihnea Costoiu is a well-known educational management expert in Romania. His experience spans international cooperation, project management, and leadership in prominent organizations such as CESAER. Formerly a Minister for Higher Education, he currently champions local education and research globally.",
    },
    {
        "d1": "Prof. dr. Engr. habil. Bogdan Ionescu",
        "d2": "Manager of the Campus Research Institute",
        "d3": "2Space is perhaps one of the most ambitious and interdisciplinary projects of the students at the CAMPUS Research Institute. Now the students are true path-breaking engineers and give birth to the next generation of creative minds. Congratulations and success to all!",
    },
    {
        "d1": "Prof. dr. Engr. Mihnea Alexandru Moisescu",
        "d2": "Dean of the Faculty of Automatic Control and Computer Science Engineering",
        "d3": "Professor Mihnea Alexandru Moisescu, the current dean of the Faculty of Automatic Control and Computer Science Engineering at the Polytechnic University of Bucharest, holds degrees in Automation and Computers, Biophysics, and a PhD in Systems Engineering. Specializing in Advanced Robotics and Communication Systems, he has written over 80 conference papers. ",
    },
    {
        "d1": "Prof. dr. Engr. Daniel Crunțeanu",
        "d2": "Dean of Faculty of Aerospace Engineering",
        "d3": "Encouraging space-enthusiast students to join this inaugural Romanian competition, I see it as a golden chance to nurture your satellite passion and knowledge. I'm proud our Aerospace Engineering Faculty, with numerous students, is involved in organizing this key contest. Kudos to our committed, determined youth for their promising future in space exploration.",
    },
    {
        "d1": "Caius Reza",
        "d2": "Head of Business and Marketing at Alba Orbital",
        "d3": "Caius Reza is the Head of Business and Marketing at Alba Orbital. Founded in Scotland in 2012, Alba Orbital is the world’s leading PocketQube satellite manufacturer and launch broker.  After graduating from the University of Strathclyde in Glasgow, Caius joined Alba Orbital in 2019 and has since gained experience on over 30 orbital PocketQube missions, working with launch vehicle providers such as SpaceX, Rocket Lab and ArianeSpace. Caius is a passionate advocate for space exploration, education and innovation. After attending the 1st edition of Qube2Space, Caius is excited to attend this year’s event!",
    },
]

const Qube2Space = (props) => {



    return (
        <div className="App">
            <Navbar />
            <header className="App-header qube2SpaceMain ">
                <div className='overlay-header-bg'></div>
                <img className="title-logo" src={logoImg} alt="" />
                <div className="w-25 d-flex" id="q2s-title">
                    <h1 id="blend-mode-title">QUBE2SPACE</h1>
                </div>
                <div className="w-25 d-flex" id="q2s-title">
                    <h2 id="blend-mode-title">2024 edition is here!</h2>
                </div>
                <div className=" mt-5 d-flex flex-row " id="buttons-row">

                    <div className="bt-rules  d-flex justify-content-center align-items-center ">
                        <a href={pdfEN} target="_blank" rel="noreferrer" id="qube2sapce">

                            <div className="btn btn4">
                                <h3>
                                    Guideline - EN
                                </h3>
                            </div>
                        </a>

                    </div>


                    <div className="bt-rules d-flex justify-content-center align-items-center ">
                        <a href={pdfRO} target="_blank" rel="noreferrer" id="qube2sapce">
                            <div className="btn btn4 ">
                                <h3>
                                    Guideline - RO
                                </h3>
                            </div>
                        </a>

                    </div>


                </div>
                <div className="mt-3 bt-rules d-flex justify-content-center align-items-center ">
                    <a href={"https://form.jotform.com/241804991399066"} target="_blank" aria-disabled="true" rel="noreferrer" id="qube2sapce">
                        <div className="btn btn4 ">
                            <h3 >
                                Register
                            </h3>
                        </div>
                    </a>
                </div>

            </header>
            <section className="departments m-0  col-12  d-flex justify-content-center" id="bg-black-blue">
                <div className=" d-block justify-content-center  w-100">


                    <div className="d-block h-auto w-100 mx-auto justify-content-center align-items-center my-5 text-white ">


                        <div className="d-sm-flex d-block justify-content-between align-items-center p-2" >
                            <div style={{ flex: 1, marginRight: '10px', marginLeft: "2rem" }}>
                                <h2 className=' mb-4 fw-bold '>About Us</h2>
                                <p>
                                    The first-ever PocketQube satellite prototypes competition in the world was organized by 2SPACE team, together with University POLITEHNICA of Bucharest - UPB, aiming to engage numerous space enthusiasts from high schools. The goal was to provide assistance and guidance in Romania's space field, which is currently in an ongoing development. Therefore, students, professors and professionals in the national space sector appreciated the initiative and the workshop was a remarkable experience for all participants. The contestants were encouraged to use their imagination in order to bring different functions and utilities to their own project, so as they proposed their projects from a theoretical point of view. After this stage, over 100 people from 17 teams were called in physical format, within the UPB’s Library, to build their prototype, aiming to secure one of the three satellites suggested for the payload of our project LUNA. During the four days of our event, both participants and their supervisors were hosted within the students residences of our university.
                                </p>

                            </div>
                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                <img src={q2s2024} alt="Description of image" style={{ maxWidth: '50%' }} />
                            </div>
                        </div>
                    </div>


                    

                    <h2 className="text-white mb-4 fw-bold">Our Jury</h2>
                    <div className="d-xxl-flex d-block w-75 mx-auto justify-content-center  ">

                        <MultiActionAreaCard img={Catalin} name="2Space Team Leader" description1={"-"} description2="Cătălin is the leader of the 2Space student team within the CAMPUS Center of the POLITEHNICA University in Bucharest and a student of the Faculty of Aerospace Engineering. Passionate about rockets, Cătălin is a member of the Romanian National Model Rocket Team, in which he won multiple medals at international competitions. In 2019, he was decorated by the Minister of Youth and Sports with the title of Master of Sport in Rocket Modeling, since then he has been constantly concerned with promoting the aerospace field among young people." />

                    </div>
                    <div className="d-xxl-flex d-block w-75 mx-auto justify-content-center justify-content-xxl-between ">

                        <MultiActionAreaCard img={Enachescu} name={descriptions[0].d1} description1={descriptions[0].d2} description2={descriptions[0].d3} />
                        <MultiActionAreaCard img={Constantinescu} name={descriptions[1].d1} description1={descriptions[1].d2} description2={descriptions[1].d3} />
                        <MultiActionAreaCard img={Ionut} name={descriptions[2].d1} description1={descriptions[2].d2} description2={descriptions[2].d3} />

                    </div>
                    <h2 className="text-white mb-4 fw-bold">Supported By</h2>
                    <div className="d-xxl-flex d-block w-75 mx-auto justify-content-center justify-content-xxl-around mb-4">

                        <MultiActionAreaCard img={Costoiu} name={descriptions[3].d1} description1={descriptions[3].d2} description2={descriptions[3].d3} />
                        <MultiActionAreaCard img={Ionescu} name={descriptions[4].d1} description1={descriptions[4].d2} description2={descriptions[4].d3} />

                    </div>




                    <div className="d-xxl-flex d-block w-75 mx-auto justify-content-center justify-content-xxl-between mb-4">

                        <MultiActionAreaCard img={Caius} name={descriptions[7].d1} description1={descriptions[7].d2} description2={descriptions[7].d3} />

                        <MultiActionAreaCard img={Mihnea} name={descriptions[5].d1} description1={descriptions[5].d2} description2={descriptions[5].d3} />
                        <MultiActionAreaCard img={Crunțeanu} name={descriptions[6].d1} description1={descriptions[6].d2} description2={descriptions[6].d3} />

                    </div>
                    <h2 className="text-white mt-5 fw-bold">Sponsors</h2>
                    <section className=' w-100 d-flex text-white  flex-column justify-content-center align-items-center bg-transparent mt-5' id="SponsorsSection">

                        <div className='  d-flex justify-content-around align-items-center flex-row  about-row ' id="sponsor-row-1">

                            <div className=' d-flex justify-content-around align-items-center ' id="sponsor-row-2">
                                <Sponsor name="UNSTPB" image={UPB_logo} description="The University POLITEHNICA of Bucharest is the oldest and most prestigious engineering school in Romania."></Sponsor>

                                <Sponsor name="VODAFONE" image={VODAFONE_logo} description="Vodafone Romania started business in 1997 and continues to deliver the best communication services."></Sponsor>
                            </div>
                        </div>
                        

                    </section>

                    <h2 className="text-white mt-5 fw-bold">Supporters</h2>
                    <section className='mb-2 w-100 d-flex flex-column justify-content-center text-white align-items-center bg-transparent mt-5' id="SponsorsSection">

                        <div className=' d-flex justify-content-around align-items-center flex-row about-row ' id="sponsor-row-1">
                            <div className=' d-flex justify-content-around align-items-center ' id="sponsor-row-2">
                                <Sponsor name="UNSTPB" image={UPB_logo} description="The University POLITEHNICA of Bucharest is the oldest and most prestigious engineering school in Romania."></Sponsor>
                                <Sponsor name="CAMPUS" image={CAMPUS_logo} description="Research Institute „CAMPUS” is UPB's advanced research and development center for multi - and inter - disciplinary technologies."></Sponsor>
                            </div>
                        </div>
{/* 
                        <div className=' d-flex justify-content-around align-items-center flex-row about-row ' id="sponsor-row-1">
                            <div className=' d-flex justify-content-around align-items-center ' id="sponsor-row-2">
                                <Sponsor name="ETTI" image={LogoETTI} description="The Romanian school of Electronics and Telecommunications was set up in 1920 as part of the Polytechnic School of Bucharest."></Sponsor>
                                <Sponsor name="CAMPUS" image={CAMPUS_logo} description="Research Institute „CAMPUS” is UPB's advanced research and development center for multi - and inter - disciplinary technologies."></Sponsor>
                            </div>

                            <div className=' d-flex justify-content-around align-items-center ' id="sponsor-row-2">
                                <Sponsor name="FIA" image={FIA_logo} description="The Faculty of Aerospace Engineering (FAE) Mission is defined in the UPB Charter and is that of advanced research and education."></Sponsor>
                                <Sponsor name="ACS" image={ACS_logo} description="The Faculty of Automatic Control and Computers prepares engineers specialized in the fields of Systems Engineering and Computers and Information Technology."></Sponsor>
                            </div>

                        </div> */}

                    </section>
                    
                    <div className="d-block   w-100 ">
                       
                        <div className="d-flex flex-column align-items-center "  >
                            <h1 clasName="sp2-section-title ">Schedule</h1>
                            <h1 clasName="sp2-section-title ">- - -</h1>
                        </div> 
                        {/* <CustomizedTimeline /> */}
                        <Timeline main_color={"#6B0FF6"} events ={events} secondary_color={"#FF5F56"} not_avaible_color={"#808080"} colorTillPasses={true}/>

                    </div>
                    {/* Scheduel section  */}

                    {/* <div className="d-flex w-100  justify-content-center ">
                        <Schedule />
                    </div> */}

                </div>

            </section>
            <Footer2024 />
        </div>
    )
}


export default Qube2Space;
