import React from "react";
import { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Nav";
import NewTeamBottomLeft from "../static/images/backgrounds/NewTeamBottomLeft.jpeg";
import NewTeamBottomRight from "../static/images/backgrounds/NewTeamTopRight.png";
import "../static/css/newTeamPage.css";
import MemberCard from "../Components/MemberCard";
import {FaFacebook, FaEnvelope, FaInstagram, FaLinkedin, FaGooglePlus} from "react-icons/fa"
import Leadericon from "../static/images/logos/space-lead.png"

import CatalinChelmus from "../static/images/Poze editate/Chelmus Catalin.jpg";

const NewTeamPage = function(){
    return <>
        <div className="App">
            <Navbar smth={[1, 2, 3]}/>
            <header className="new_team_page_header">
                <div className='overlay-header-team'>

                    <div className="overlay-header-row">
                        <div className="overlay_header_column">
                            <div className="page-title-border">
                                <h1>Team</h1>
                                <h1>Members</h1>
                            </div>  
                        </div>
                        <div className="overlay_header_column_image">
                            <img src={NewTeamBottomRight} id="cover_div_size"/>
                            <div className="rigth-shadow-gradient">

                            </div>
                        </div>
                    </div>

                    <div className="overlay-header-row">
                        <div className="overlay_header_column_image">
                            <img src={NewTeamBottomLeft} id="cover_div_size" />
                            <div className="left-shadow-gradient">

                            </div>
                        </div>
                        <div className="overlay_header_column">
                            
                        </div>
                    </div>
                    
                </div>

                <div className='overlay-header-team_leader' >
                    <div className={" d-flex justify-content-center align-items-center"} width="100%" >
                        <div className={"col-12 d-flex justify-content-center"} width>
                            <div className="our-team d-flex justify-content-center">
                                <img src={CatalinChelmus} id="new-team_leader" alt=""/>
                                <ul className="social">
                                    <li><a href={"#"} className="fab fa-instagram"><FaInstagram className="w-75"/></a></li>
                                    <li><a href={"#"} className="fab fa-linkedin"><FaEnvelope className="w-75"/></a></li>
                                </ul>
                                <div className="team-content">
                                    <h3 className="title">Cătălin Chelmuș</h3>
                                    {/* <span className="post">Team Leader</span> */}
                                </div>
                                <div className="div-role-icon">
                                    <img src={Leadericon} />
                                </div>
                                
                            </div>
                        </div>

                        <div className="card_flip">
                            <div className="card__side card__side--front-1 expanding-possibilties">
                                <p className="text-start roboto-black"  id="leader_card_about_us">About us</p> 
                                <p className="text-start" id="leader_card_text">EXPANDING POSSIBILITES</p> 
                                <ul>
                                    <li className="text-start" id="leader_card_li">beyond the sky</li>
                                </ul>
                            </div>
                            <div className="card__side card__side--back">
                                <p className="text-start roboto-black"  id="leader_card_about_us">Since 2019</p> 
                                <p className="text-start" id="leader_card_text">Hard working </p> 
                                <p className="text-start" id="leader_card_text">resourceful</p> 

                                <ul>
                                    <li className="text-start" id="leader_card_li">achieving higher goals</li>
                                </ul>
                            </div>
                        </div>
                        
                        

                    </div>
                    {/* <MemberCard image={CatalinChelmus} name="Cătălin Chelmuș" role="Team Leader" col="12" width={"60%!important"}/> */}
                </div>
            </header>
        </div>
    </>

}

export default NewTeamPage