import React from 'react';
import LunaPozaGrup from '../../static/images/LunaImages/luna-poza-grup.jpeg';

const TeamHistoryLuna = () => {
  return (
    <div className='luna-team-history-container d-flex flex-column justify-content-center align-items-center' >
        <div luna-team-history-title>
            <p className='luna-team-history-primary-heading'>About Spaceport</p>
            <p className='luna-team-history-primary-heading-gradient'>America Cup</p>
        </div>

        <div className='luna-team-history-box d-flex flex-row justify-content-center align-items-center mt-4 w-75'>
            <div className='luna-team-history-text-in-box d-flex flex-row justify-content-center align-items-center mt-4 w-100'>The Spaceport America Cup is the world’s largest IREC – Intercollegiate Rocket Engineering Competition for student rocketry teams, with over 140 teams from colleges and universities in 32 countries.
We’re the first Romanian team of students & engineers that got accepted at the Spaceport America Cup. We are proud that we were one of the 158 teams that were selected in 2023, becoming the first Romanian students team to ever launch on USA soil.
</div>
            <div className='luna-team-history-image-in-box d-flex flex-row justify-content-center align-items-center'> <img src={LunaPozaGrup} alt="" /></div>
        </div>
    </div>
  )
}

export default TeamHistoryLuna